import { Component, OnInit } from '@angular/core';
import { ColumnSettings } from '../../common/models/columnSettings';
import * as global from '../../constants/baseurl';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateDropDownValue } from '../../common/validators/common-dropdown.validators';
import { UserService } from '../../all-users/services/user.service';
import { ReportsService } from '../reports.service';
import { DateToEpochPipe } from '../../common/pipes/date-to-epoch.pipe';

@Component({
  selector: 'app-guest-list',
  templateUrl: './guest-list.component.html',
  styleUrls: ['./guest-list.component.scss']
})
export class GuestListComponent implements OnInit {
  selecteditem = "guest_checked_in";
  header = "Guest Management";

  columnSettings1: ColumnSettings[] = [];
  columnSettings2: ColumnSettings[] = [];
  columnSettings3: ColumnSettings[] = [];

  columnSettings4: ColumnSettings[] = [];
  columnSettings5: ColumnSettings[] = [];
  columnSettings6: ColumnSettings[] = [];

  guest_checked_inTableData: any = [];
  guest_checked_outTableData: any = [];
  guest_not_checked_outTableData: any = [];
  sp_checked_inTableData: any = [];
  sp_checked_outTableData: any = [];
  sp_not_checked_outTableData: any = [];

  guests_checked_in    : any = [];
  guests_checked_out   : any = [];
  guests_notchecked_out: any = [];
  sp_checked_in        : any = [];
  sp_checked_out       : any = [];
  sp_notchecked_out    : any = [];

  showTable1: boolean;
  showTable2: boolean;
  showTable3: boolean;
  showTable4: boolean;
  showTable5: boolean;
  showTable6: boolean;

  reloadTable1: boolean;
  reloadTable2: boolean;
  reloadTable3: boolean;
  reloadTable4: boolean;
  reloadTable5: boolean;
  reloadTable6: boolean;
  reload      : boolean = false;
  isValidDate : boolean = true;

  loginDetails    : any;
  clientId        : any;
  communityId     : any;
  GuestForm       : FormGroup;
  communityList   : any[];
  startDate       : any;
  endDate         : any;
  _convertDate  : DateToEpochPipe;
  
  


  constructor(private router: ActivatedRoute, private route: Router,private userService: UserService, private reportService: ReportsService, private spinner: NgxSpinnerService,private fbuilder: FormBuilder) {

    this._convertDate = new DateToEpochPipe();
    this.GuestForm = this.fbuilder.group({
      'communities': ["-1", Validators.compose([Validators.required, validateDropDownValue])],
      'start_date': ["", [Validators.required]],
      'end_date':  ["", [Validators.required]],
    })
   }

  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem('userdata'));
    this.clientId = this.loginDetails.data.id;

    this.communityList = []
    this.communityList.push({ label: 'Select Community', value: '-1' })
    this.userService.getAllCommunity().subscribe(response => {
      response['data'].forEach(element => {
        this.communityList.push({ label: element.name, value: element.id });
      });
    })

    this.columnSettings1.push({
      primaryKey : 'unit',
      header : 'Unit'
    },{
      primaryKey : 'name',
      header : 'Name'
    },{
      primaryKey : 'phone_number',
      header : 'Phone number'
    },{
      primaryKey : 'date_of_visit',
      header : 'Date of visit'
    },{
      primaryKey :'action',
      header : 'Action'
    });

    this.columnSettings2.push({
      primaryKey : 'unit',
      header : 'Unit'
    },{
      primaryKey : 'name',
      header : 'Name'
    },{
      primaryKey : 'phone_number',
      header : 'Phone number'
    },{
      primaryKey : 'date_of_visit',
      header : 'Date of visit'
    },{
      primaryKey :'action',
      header : 'Action'
    });

    this.columnSettings3.push({
      primaryKey : 'unit',
      header : 'Unit'
    },{
      primaryKey : 'name',
      header : 'Name'
    },{
      primaryKey : 'phone_number',
      header : 'Phone number'
    },{
      primaryKey : 'date_of_visit',
      header : 'Date of visit'
    },{
      primaryKey :'action',
      header : 'Action'
    });
    this.columnSettings4.push({
      primaryKey : 'unit',
      header : 'Unit'
    },{
      primaryKey : 'name',
      header : 'Name'
    },{
      primaryKey : 'phone_number',
      header : 'Phone number'
    },{
      primaryKey : 'date_of_visit',
      header : 'Date of visit'
    },{
      primaryKey :'action',
      header : 'Action'
    });
    this.columnSettings5.push({
      primaryKey : 'unit',
      header : 'Unit'
    },{
      primaryKey : 'name',
      header : 'Name'
    },{
      primaryKey : 'phone_number',
      header : 'Phone number'
    },{
      primaryKey : 'date_of_visit',
      header : 'Date of visit'
    },{
      primaryKey :'action',
      header : 'Action'
    });
    this.columnSettings6.push({
      primaryKey : 'unit',
      header : 'Unit'
    },{
      primaryKey : 'name',
      header : 'Name'
    },{
      primaryKey : 'phone_number',
      header : 'Phone number'
    },{
      primaryKey : 'date_of_visit',
      header : 'Date of visit'
    },{
      primaryKey :'action',
      header : 'Action'
    });

    this.navigateTo(this.selecteditem)

  }

  changeCommunity(value){
    this.communityId = value;
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  }

  val: any = { isError: false, errorMessage: '' };
  validateDates(sDate: string, eDate: string) 
  {
    sDate = this._convertDate.convertDateToYYMMDD(sDate);
    eDate = this._convertDate.convertDateToYYMMDD(eDate);
    
    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {
      this.val = { isError: true, errorMessage: 'End date should be greater than start date.' };
      this.isValidDate = false;
    }
    else if((sDate != null && eDate != null) && (eDate) == (sDate)){
      this.val = { isError: false, errorMessage: '' };
      this.isValidDate = true;
    }
    else{
      this.val = { isError: false, errorMessage: '' };
      this.isValidDate = true;
    }
    return this.isValidDate;
  }

  actionClick(event){
    console.log(event);
    this.route.navigate(['guest-registration/guest-details',{ id: event.records.id, action:  'view' }]);

  }

  getGuestsCheckedIn(id,startdate,enddate){
    this.guests_checked_in = [];
    this.showTable1 = true;
    this.reload = true;
    this.dataLoader();
    this.reportService.getGuestsCheckedIn(id,startdate,enddate).subscribe(response => {
      if(response != null)
      this.guests_checked_in  = response['data'];
      },()=> {
      },() => { 
        this.loadData1();
        this.reload = false; 
      });
  }
    getGuestsCheckedOut(id,startdate,enddate){
    this.guests_checked_out = [];
    this.showTable2 = true;
    this.reload = true;
    this.dataLoader();
    this.reportService.getGuestsCheckedOut(id,startdate,enddate).subscribe(response => {
      if(response != null)
      this.guests_checked_out  = response['data'];
      },()=> {
      },() => { 
        this.loadData2();
        this.reload = false; 
      });
  }
    getGuestsNotCheckedOut(id,startdate,enddate){
      this.guests_notchecked_out = [];
      this.showTable3 = true;
      this.reload = true;
      this.dataLoader();
      this.reportService.getGuestsNotCheckedOut(id,startdate,enddate).subscribe(response => {
        if(response != null)
        this.guests_notchecked_out  = response['data'];
        },()=> {
        },() => { 
          this.loadData3();
          this.reload = false; 
        });
    }
  getSPsCheckedIn(id,startdate,enddate){
    this.sp_checked_in = [];
    this.showTable4 = true;
    this.reload = true;
    this.dataLoader();
    this.reportService.getSPsCheckedIn(id,startdate,enddate).subscribe(response => {
      if(response != null)
      this.sp_checked_in  = response['data'];
      },()=> {
      },() => { 
        this.loadData4();
        this.reload = false; 
      });
  }
  getSPsCheckedOut(id,startdate,enddate){
    this.sp_checked_out = [];
    this.showTable2 = true;
    this.reload = true;
    this.dataLoader();
    this.reportService.getSPsCheckedOut(id,startdate,enddate).subscribe(response => {
      if(response != null)
      this.sp_checked_out  = response['data'];
      },()=> {
      },() => { 
        this.loadData5();
        this.reload = false; 
      });
  }
  getSPsNotCheckedOut(id,startdate,enddate){
    this.sp_notchecked_out = [];
    this.showTable6 = true;
    this.reload = true;
    this.dataLoader();
    this.reportService.getSPsNotCheckedOut(id,startdate,enddate).subscribe(response => {
      if(response != null)
      this.sp_notchecked_out  = response['data'];
      },()=> {
      },() => { 
        this.loadData6();
        this.reload = false; 
      });
  }
  loadData1() {
    if(this.guests_checked_in.length != 0) {
      this.guests_checked_in.basePojoList.forEach((element) => {
        this.guest_checked_inTableData.push({ 
          id             : element.id,
          unit           : element.unit != undefined ? element.unit.name : '' ,
          name           : element.name != undefined ? element.name : '',
          phone_number   : element.phoneNo != undefined ?  element.phoneNo  : '',
          date_of_visit  : element.dateOfVisit != undefined ? this.convert(element.dateOfVisit) : '',
          action : {
           view : true
          }
          })
      })

    } 
  }
  loadData2() {
    if(this.guests_checked_out.length != 0) {
      this.guests_checked_out.basePojoList.forEach((element) => {
        this.guest_checked_outTableData.push({ 
          id             : element.id,
          unit           : element.unit != undefined ? element.unit.name : '' ,
          name           : element.name != undefined ? element.name : '',
          phone_number   : element.phoneNo != undefined ?  element.phoneNo  : '',
          date_of_visit  : element.dateOfVisit != undefined ? this.convert(element.dateOfVisit) : '',
          action : {
           view : true
          }
          })
      })

    } 
  }
  loadData3() {
    if(this.guests_notchecked_out.length != 0) {
      this.guests_notchecked_out.basePojoList.forEach((element) => {
        this.guest_not_checked_outTableData.push({ 
          id             : element.id,
          unit           : element.unit != undefined ? element.unit.name : '' ,
          name           : element.name != undefined ? element.name : '',
          phone_number   : element.phoneNo != undefined ?  element.phoneNo  : '',
          date_of_visit  : element.dateOfVisit != undefined ? this.convert(element.dateOfVisit) : '',
          action : {
           view : true
          }
          })
      })

    } 
  }
  loadData4() {
    if(this.sp_checked_in.length != 0) {
      this.sp_checked_in.basePojoList.forEach((element) => {
        this.sp_checked_inTableData.push({ 
          id             : element.id,
          unit           : element.unit != undefined ? element.unit.name : '' ,
          name           : element.name != undefined ? element.name : '',
          phone_number   : element.phoneNo != undefined ?  element.phoneNo  : '',
          date_of_visit  : element.dateOfVisit != undefined ? this.convert(element.dateOfVisit) : '',
          action : {
           view : true
          }
          })
      })

    } 
  }
  loadData5() {
    if(this.sp_checked_out.length != 0) {
      this.sp_checked_out.basePojoList.forEach((element) => {
        this.sp_checked_outTableData.push({ 
          id             : element.id,
          unit           : element.unit != undefined ? element.unit.name : '' ,
          name           : element.name != undefined ? element.name : '',
          phone_number   : element.phoneNo != undefined ?  element.phoneNo  : '',
          date_of_visit  : element.dateOfVisit != undefined ? this.convert(element.dateOfVisit) : '',
          action : {
           view : true
          }
          })
      })

    } 
  }
  loadData6() {
    if(this.sp_notchecked_out.length != 0) {
      this.sp_notchecked_out.basePojoList.forEach((element) => {
        this.sp_not_checked_outTableData.push({ 
          id             : element.id,
          unit           : element.unit != undefined ? element.unit.name : '' ,
          name           : element.name != undefined ? element.name : '',
          phone_number   : element.phoneNo != undefined ?  element.phoneNo  : '',
          date_of_visit  : element.dateOfVisit != undefined ? this.convert(element.dateOfVisit) : '',
          action : {
           view : true
          }
          })
      })
    } 
  }
  
  navigateTo(value: string) {

    this.selecteditem = value;
    this.startDate = this._convertDate.convertDateToYYMMDD(this.GuestForm.controls['start_date']['value']);
    this.endDate = this._convertDate.convertDateToYYMMDD(this.GuestForm.controls['end_date']['value']);
    this.isValidDate  = this.validateDates(this.startDate,this.endDate);

    this.guest_checked_inTableData = [];
    this.guest_checked_outTableData = [];
    this.guest_not_checked_outTableData = [];
    this.sp_checked_inTableData = [];
    this.sp_checked_outTableData = [];
    this.sp_not_checked_outTableData = [];

    if(this.selecteditem == 'guest_checked_in'){
      this.showTable1 = true;
    }
    else if(this.selecteditem == 'guest_checked_out'){
      this.showTable2 = true;
    }
    else if(this.selecteditem == 'guest_not_checked_out'){
      this.showTable3 = true;
    }
    else if(this.selecteditem == 'sp_checked_in'){
      this.showTable4 = true;
    }
    else if(this.selecteditem == 'sp_checked_out'){
      this.showTable5 = true;
    }
    else if(this.selecteditem == 'sp_not_checked_out'){
      this.showTable6 = true;
    }
    if(this.GuestForm.valid && this.isValidDate){
      if (this.selecteditem == 'guest_checked_in') {
        this.guest_checked_inTableData = [];
        this.getGuestsCheckedIn(this.communityId,this.startDate,this.endDate);
      } 
      else if (this.selecteditem == 'guest_checked_out') {
        this.guest_checked_outTableData = [];
        this.getGuestsCheckedOut(this.communityId,this.startDate,this.endDate);
      } 
      else if (this.selecteditem == 'guest_not_checked_out') {
        this.guest_not_checked_outTableData = [];
        this.getGuestsNotCheckedOut(this.communityId,this.startDate,this.endDate);
      }
      else if (this.selecteditem == 'sp_checked_in') {
        this.sp_checked_inTableData = [];
        this.getSPsCheckedIn(this.communityId,this.startDate,this.endDate);
      }
      else if (this.selecteditem == 'sp_checked_out') {
        this.sp_checked_outTableData = [];
        this.getSPsCheckedOut(this.communityId,this.startDate,this.endDate);
      }
      else {
        this.sp_not_checked_outTableData = [];
        this.getSPsNotCheckedOut(this.communityId,this.startDate,this.endDate);
      }
    } 
  }
  searchResult(formValue){
    this.guest_checked_inTableData = [];
    this.guest_checked_outTableData = [];
    this.guest_not_checked_outTableData = [];
    this.sp_checked_inTableData = [];
    this.sp_checked_outTableData = [];
    this.sp_not_checked_outTableData = [];
    this.startDate = this._convertDate.convertDateToYYMMDD(formValue.start_date);
    this.endDate = this._convertDate.convertDateToYYMMDD(formValue.end_date);
    this.isValidDate  = this.validateDates(this.startDate,this.endDate);
    console.log("date checked",this.isValidDate)
    if (this.GuestForm.invalid ) {
      global.markFormGroupTouched(this.GuestForm);
    }
    else{
      if(this.isValidDate){
  
        if (this.selecteditem == 'guest_checked_in') {
          this.getGuestsCheckedIn(this.communityId,this.startDate,this.endDate);
        } 
        else if (this.selecteditem == 'guest_checked_out') {
          this.getGuestsCheckedOut(this.communityId,this.startDate,this.endDate);
        }
        else if (this.selecteditem == 'guest_not_checked_out') {
          this.getGuestsNotCheckedOut(this.communityId,this.startDate,this.endDate);
        }
        else if (this.selecteditem == 'sp_checked_in') {
          this.getSPsCheckedIn(this.communityId,this.startDate,this.endDate);
        }
        else if (this.selecteditem == 'sp_checked_out') {
          this.getSPsCheckedOut(this.communityId,this.startDate,this.endDate);
        } 
        else {
         
          this.getSPsNotCheckedOut(this.communityId,this.startDate,this.endDate);
        }
      }
    }


  } 
  dataLoader(){
    /** spinner starts on init */
    if(this.reload)
       this.spinner.show();
    else
        this.spinner.hide();
    }
}
