import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatHtml'
})
export class FormatHtmlPipe implements PipeTransform {
  
  docs = [17,15,16,18,1,2]


  transform(value: any, ...args: any[]): any {
    var html = `<div class="row custom_row">`
    for (var i = 0; i < value.length; i++) {
      if (this.docs.includes(value[i].documentType.id)) {

        html = html + '<div class="col-sm-4"><div><a class="text_style" style="color: gray;">' + value[i].documentType.typeName + 
        '</a> </div><br><div><a href="' + value[i].documentName + '" target="_blank"><img src="../assets/doc.png" width="40px"></a>'

        if(value[i].documentType.id != 17 && value[i].expiryDate != undefined) {
          
          html = html + '<a style="color: gray; padding:  15px;">Expiry:- <a style="color: rgb(46, 44, 44)">' + this.convert(value[i].expiryDate) + '</a>'
        }
        html = html + '</div> <br></div>'
      }
    }
    return html;
  }

  convert(str) {

    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [ day,mnth,date.getFullYear()].join("-");
  }
}
