import { Injectable } from '@angular/core';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
@Injectable()
export class MessagingService {
// currentMessage = new BehaviorSubject(null);

constructor() {
// this.angularFireMessaging.messaging.subscribe(
// (_messaging) => {
// _messaging.onMessage = _messaging.onMessage.bind(_messaging);
// _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
// }
// )
}

// requestPermission() {
// this.angularFireMessaging.requestToken.subscribe(
// (token) => {
// console.log("token received",token);
// },
// (err) => {
// console.error('Unable to get permission to notify.', err);
// }
// );
// }

// receiveMessage() {
// this.angularFireMessaging.messages.subscribe(
// (payload) => {
// console.log("new message received. ", payload);
// this.currentMessage.next(payload);
// })
// }
}
