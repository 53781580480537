import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-notification-menu-detail',
  templateUrl: './notification-menu-detail.component.html',
  styleUrls: ['./notification-menu-detail.component.scss']
})
export class NotificationMenuDetailComponent implements OnInit {

  @ViewChild('primaryModal', { static: false }) public primaryModal: ModalDirective;
  title: string = ""
  description: string = ""
  link = ""

  constructor() { }

  ngOnInit() {}
  
  showPopup(record) {
    console.log(record)
    this.title = record.title
    this.description = record.description
    this.link = record.link
    this.primaryModal.show();
  }

  dismissModal() {
    this.primaryModal.hide()
  }

  openLink() {
    window.open(this.link,"_blank")
  }
}
