import { Injectable } from '@angular/core';
import { baseUrl } from '../../constants/baseurl';
import { Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
 
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { 
    // console.log("login service") 
  }

  // login
  login(loginId: string, password: string): Observable<any>
  {
    return this.http.post(baseUrl+'login',{loginId: loginId, password: password }).pipe()
  }

  // change password
  updatePassword(currentPassword: string, newPassword: string): Observable<any> {
    let client_id = localStorage.getItem('clientId')
    return this.http.patch(baseUrl + 'clients/' + client_id + '?action=patchPassword', { oldPassword: currentPassword, password: newPassword }).pipe()
  }
}
