import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-core-input-file',
  templateUrl: './core-input-file.component.html',
  styleUrls: ['./core-input-file.component.scss']
})
export class CoreInputFileComponent implements OnInit {
@Input () required        : boolean = true;
@Input () label           : String;
@Input () form_group      : FormGroup;
@Input () form_field      : FormControlName;
@Output() change          = new EventEmitter()
  constructor() { }

  ngOnInit() {
  }
  changeFile($event){
    this.change.emit($event)
  }
  // onChange(event: any) {
  //   let files = [].slice.call(event.target.files);
  //   console.log("haaaiii",files)
  
  //   this.input= files.map(f => f.name).join(', ');
  //   if(files.map(f => f.name).join(', '))
  //      console.log("baiiiii",this.input)
  //     this.fileData.emit();
  // }
}
