import { Injectable } from '@angular/core';
import { baseUrl } from '../../constants/baseurl';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FirstTimeLoginService {

  constructor(private http: HttpClient) { }

    firsttimeLogin(otp :string, password: string,loginId:string): Observable<any> {
      return this.http.post(baseUrl + 'verifyOtpWithPassword', { otp :otp,password:password,loginId:loginId }).pipe()
    }
}
