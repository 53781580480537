import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-core-button',
  templateUrl: './core-button.component.html',
  styleUrls: ['./core-button.component.scss']
})
export class CoreButtonComponent implements OnInit {
  @Input() form_group   :FormGroup;
  @Input() btnTxt       : String;
  @Input() btnClass     : String;
  @Input() btnType      : String;
  @Input() iconClass    : String;
  @Output() btnClick    = new EventEmitter()
  @Input() isDisabled   : boolean = false
  constructor() { }

  ngOnInit() {
  }
  triggerBtnClick(){
    this.btnClick.emit()
  }
}
