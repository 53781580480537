import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-core-radio-button',
  templateUrl: './core-radio-button.component.html',
  styleUrls: ['./core-radio-button.component.scss']
})
export class CoreRadioButtonComponent implements OnInit {

  @Input () form_group : FormGroup;
  @Input () form_field : FormControlName;
  @Input () orders     = [];
  @Input () isError    : Boolean = false;
  @Input () errorText  : any;
  @Input () value;


  isApproved           : Boolean = null;
  constructor() { }

  ngOnInit() {
  }

}
