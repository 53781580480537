import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CommunityService } from '../../community/community.service';
import { Dropdown } from '../../common/models/dropdown';
import { Router, ActivatedRoute } from '@angular/router';
import * as global from '../../constants/baseurl';
import { BulletinBoardsService } from '../bulletin-boards.service';
import { validateDropDownValue } from '../../common/validators/common-dropdown.validators';
import { element } from 'protractor';
import { DateToEpochPipe } from '../../common/pipes/date-to-epoch.pipe';
import { ReasonForRejectionComponent } from '../../all-users/unit-users/reason-for-rejection/reason-for-rejection.component'
import * as countryCode from '../../constants/countryCode';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manage-bulletin-boards',
  templateUrl: './manage-bulletin-boards.component.html',
  styleUrls: ['./manage-bulletin-boards.component.scss']
})
export class ManageBulletinBoardsComponent implements OnInit {
  bulletinForm: FormGroup;
  title: string = '';
  countryCode: Dropdown[] = [];
  community: Dropdown[] = [];
  communitLoads: boolean = false;
  isShowForm: boolean = false;
  clone = {};
  documentName: any;
  private items: Array<any> = [];

  editClass: any;
  userId: any;
  photosId_file: File;
  communityList: any;
  header: any;
  userData: any;
  clientId: any;
  _convertDate: DateToEpochPipe
  commentDetails: any;
  val: any = { isError: false, errorMessage: '' };
  comment: any[];
  approveClass: boolean;
  disable_button : boolean = false;
  @ViewChild('modal', { static: true }) modal: ReasonForRejectionComponent;
  reasonForRejection: any;
  selectedMenu: any;
  constructor(private location: Location, private fbuilder: FormBuilder, private route: ActivatedRoute, private CommunityService: CommunityService, private router: Router, private BulletinBoardsService: BulletinBoardsService) {
    let value = this.route.params.subscribe(params => {
      this.userId = params.id;
      this.header = params.action;
      this.selectedMenu = params.menu;
      this.title = this.header + " Bulletin boards";
    });
    this.bulletinForm = fbuilder.group({
      title: ['', [Validators.required]],
      upload_photo: [''],
      photo_upload: [''],
      documentName: [""],
      comments: [""],
      countryCode: ['-1', Validators.compose([Validators.required, validateDropDownValue])],
      community: ["-1", Validators.compose([Validators.required, validateDropDownValue])],
      description: ["", Validators.required],
      contact_number: ["", Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(16), Validators.pattern(global.numberType)])],
    }, { updateOn: 'blur' })

    this._convertDate = new DateToEpochPipe();
  }
  get comments(): any {
    return this.bulletinForm.get('comments');
  }
  clearComment() {
    this.comments.reset();
  }
  ngOnInit() {
    console.log(this.selectedMenu, "selected....")
    this.countryCode.push({ value: "-1", label: 'Country code' })

    let countries = Object.keys(countryCode.countryCode);
    countries.forEach(element => {
      this.countryCode.push({ value: countryCode.countryCode[element], label: countryCode.countryCode[element] + '(' + element + ')' })
    })

    this.userData = JSON.parse(localStorage.getItem('userdata'));
    this.clientId = this.userData['data'].id;

    this.editClass = this.header;
    if (this.editClass == "Edit") {
      this.editClass = true;
      this.getComments();
    } else if (this.editClass == "Approve / Reject") {
      this.approveClass = true;
    } else {
      this.editClass = false;
      this.approveClass = false;
    }

    //community list
    this.community.push({ label: 'Select Community', value: '-1' });
    this.CommunityService.getCommunity().subscribe(response => {
      this.communityList = response['data'];
      this.communityList.forEach(element => {
        this.community.push({ label: element.name, value: element.id })
      })
    })

    if (this.userId != undefined && this.userId) {
      this.BulletinBoardsService.getBulletinsById(this.userId).subscribe(response => {
        let bulletinDetails = response['data'];
        this.bulletinForm.patchValue({
          title: bulletinDetails.title != undefined ? bulletinDetails.title : '',
          description: bulletinDetails.description != undefined ? bulletinDetails.description : '',
          contact_number: bulletinDetails.contactNo != undefined ? bulletinDetails.contactNo : '',
          community: bulletinDetails.community != undefined ? bulletinDetails.community.id : '',
          photo_upload: bulletinDetails.document != undefined ? bulletinDetails.document.documentName : '',
          countryCode: bulletinDetails.countryCode != undefined ? bulletinDetails.countryCode : '',
        })
        this.documentName = bulletinDetails.document.documentName;
      })
    } else {
      this.setValidationForRegister();
    }

  }
  private setValidationForRegister() {
    const upload = this.bulletinForm.get('photo_upload');
    upload.setValidators([Validators.required]);
    upload.updateValueAndValidity();

  }

  readUrl_data(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        var url = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
      var fileElement = event.target;
      this.clone[fileElement.id] = fileElement.cloneNode(true)
    }
  }
  fileChange(e: Event) {
    var target: HTMLInputElement = e.target as HTMLInputElement;
    for (var i = 0; i < target.files.length; i++) {
      this.photosId_file = target.files[i];
    }

    if (target.value == '') {
      var file = document.getElementById("file_upload");
      file.parentNode.removeChild(file);
      document.getElementById("filediv").appendChild(this.clone[target.id]);
    }
    this.bulletinForm.patchValue({
      photo_upload: this.photosId_file
    })
  }
  addComments(comments) {
    if (comments != '') {
      const data = {
        "bulletinBoardComment": comments
      }
      this.BulletinBoardsService.postComments(data, this.userId).subscribe(response => {

      }, (error) => {
        global.showSwalMessage(error.error.message, 'error', 'Error!')
      }, () => {
        this.getComments();
        this.clearComment();
        this.val = { isError: false, errorMessage: '' };
      })
    } else {
      this.val = { isError: true, errorMessage: 'Please enter a valid comment.' };
    }

  }
  getComments() {
    this.comment = [];
    this.BulletinBoardsService.getComments(this.userId).subscribe(response => {
      if (response == null) {
        this.commentDetails = '';
      } else {
        this.commentDetails = response['data'];
        this.commentDetails.forEach(element => {
          this.comment.push({ bulletinBoardComment: element.bulletinBoardComment, createdBy: element.createdBy.firstName + ' ' + element.createdBy.lastName, designation: element.bulletinBoard.createdBy.designation != "" ? element.bulletinBoard.createdBy.designation : "", createdTime: this._convertDate.convertDateandTimeFromEpoch(element.createdTime) });
        })
      }
    })
  }
  addBulletins(formValue) {
    if (this.bulletinForm.valid) {
      this.disable_button = true;
      if (this.editClass == true && this.userId != undefined && this.userId) {
        let formData: FormData = new FormData();
        formData.append("title", formValue.title);
        formData.append("community.id", formValue.community);
        formData.append("description", formValue.description);
        formData.append("contactNo", formValue.contact_number);
        formData.append('countryCode', formValue.countryCode);

        if (this.photosId_file != undefined) {
          formData.append('document.file', formValue.photo_upload);
          formData.append('document.documentType.id', "26");
        }

        this.BulletinBoardsService.editBulletin(formData, this.userId).subscribe(response => {

        }, (error) => {
          this.disable_button = false;
          global.showSwalMessage(error.error.message, 'error', 'Error!')
        }, () => {
          global.showSwalMessage('Bulletins updated successfully', 'success', 'Success!').then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/bulletin-boards/bulletin-boards')
            }
          })
        })

      } else if (this.editClass == "Approve / Reject" && this.userId != undefined && this.userId) {
        let formData: FormData = new FormData();
        formData.append("title", formValue.title);
        formData.append("community.id", formValue.community);
        formData.append("description", formValue.description);
        formData.append("contactNo", formValue.contact_number);
        formData.append("isApproved", "APPROVED");
        formData.append('countryCode', formValue.countryCode);
        if (this.photosId_file != undefined) {
          formData.append('document.file', formValue.photo_upload);
          formData.append('document.documentType.id', "26");
        }

        this.BulletinBoardsService.editBulletin(formData, this.userId).subscribe(response => {

        }, (error) => {
          this.disable_button = false;
          global.showSwalMessage(error.error.message, 'error', 'Error!')
        }, () => {
          global.showSwalMessage('Bulletins approved successfully', 'success', 'Success!').then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/bulletin-boards/bulletin-boards')
            }
          })
        })
      }
      else {
        let formData: FormData = new FormData();
        formData.append("title", formValue.title);
        formData.append("community.id", formValue.community);
        formData.append("description", formValue.description);
        formData.append("contactNo", formValue.contact_number);
        formData.append('countryCode', formValue.countryCode);
        formData.append('document.file', formValue.photo_upload);
        formData.append('document.documentType.id', "26");

        this.BulletinBoardsService.createBulletin(formData, this.clientId).subscribe(response => {

        }, (error) => {
          this.disable_button = false;
          global.showSwalMessage(error.error.message, 'error', 'Error!')
        }, () => {
          global.showSwalMessage('Bulletins added successfully', 'success', 'Success!').then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/bulletin-boards/bulletin-boards')
            }
          })
        })
      }
    } else {
      this.markFormGroupTouched(this.bulletinForm);
    }
  }
  cancelClik() {
    this.router.navigate(['/bulletin-boards/bulletin-boards', { menu: this.selectedMenu }]);
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  showModal(value) {
    this.modal.showPopup(value);
  }
  rejectBulletins(reason) {
    this.reasonForRejection = reason;
    let formData: FormData = new FormData();
    formData.append("isApproved", "REJECTED");
    formData.append("rejectReason", this.reasonForRejection);
    this.BulletinBoardsService.editBulletin(formData, this.userId).subscribe(response => {
      console.log(response)
    }, () => {
      global.showSwalMessage("Something went wrong!", 'error', 'Error');
    }, () => {
      global.showSwalMessage("Rejected !", 'success', 'Success').then(result => {
        this.location.back();
      })
    })
  }
}

