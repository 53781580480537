import { Component } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import {AuthService} from '../../common/shared-service/auth-service';
import { ForgotPasswordService } from '../services/forgot-password.service';
import { AllUsersService } from '../../all-users/all-users.service'
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls : ['./login.component.scss']
})
export class LoginComponent {
  title             = "Login";
  error             : boolean = false;
  submitted         : boolean = false;
  isFirstTimeLogin  : boolean = false;
  userData          : any;
  loginForm         : FormGroup;
  loginId           : any;
  userId            : any;
  userDetails       : any;
  errorDisplayTxt   = "Invalid Username or Password";
           
  constructor(private allUsersService:AllUsersService,private formBuilder: FormBuilder,private router:Router,private LoginService: LoginService,private authService :AuthService,private forgotPwdService: ForgotPasswordService) {
    this.userData = JSON.parse(localStorage.getItem('userdata'));

    if(this.authService.getAuthToken() && this.userData != null) {
      this.router.navigate(['/dashboard'])
    }
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(/^([_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,5}))$/)]], //email or number with min 9 or max 16 digits
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    
  }

  ngOnInit() {


    
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit(formValue) {
    this.submitted = true;
    if (this.loginForm.invalid) {

      this.error   = true;
      this.errorDisplayTxt = "Invalid Username or Password"
      return;
    } else {

        this.error = false;
        this.LoginService.login(formValue.username,formValue.password).subscribe(response=> {

        this.isFirstTimeLogin     = false;
        this.userData             = response['data'];
      },() => {

        this.error = true;
      },()=> {
        this.authService.setAuthToken(this.userData.authToken,this.loginForm.value.email);
        localStorage.setItem('clientId',JSON.stringify(this.userData.id))
        localStorage.setItem('data', JSON.stringify(this.userData));
        if(this.userData.isFirstLogin) {

          this.forgotPwdService.forgotPassword(formValue.username).subscribe(response => {

          }, (error) => {
            
            this.error             = true
            this.errorDisplayTxt   = error
          }, () => {

            this.userId            = formValue.username;
            this.isFirstTimeLogin  = true;
          })
         
        } else {

          this.allUsersService.getAdminUserDetail(this.userData.id).subscribe(response => {
            this.userDetails      = response
            
            let isInternalUser    = response['data'].userGroup != undefined ? response ['data'].userGroup.groupType.toLowerCase().includes('internal user') || response ['data'].userGroup.groupType.toLowerCase().includes('super admin') || response ['data'].userGroup.groupType.toLowerCase().includes('service provider staff') || response ['data'].userGroup.groupType.toLowerCase().includes('service provider') || response ['data'].userGroup.groupType.toLowerCase().includes('security') : false
            if(isInternalUser) {

              this.error          = false
              localStorage.setItem('userdata', JSON.stringify(this.userDetails));
              if(this.userData.designation == "staff"){
                this.router.navigate(['/service-request/owned-by-my-group/assignedToMe']);
              }else {               
                this.router.navigate(['/dashboard']);
              }
                           
            } else {
              this.error           = true
              this.errorDisplayTxt = "Invalid Username or Password";
              localStorage.clear()
              this.router.navigate(['/login'])
            }
          })
        }
      });
    }
  }

  redirect($event) {
    if ($event)
      this.router.navigate(['/dashboard'])
  }

 }