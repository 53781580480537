import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators';
import { AuthService } from './auth-service';
import * as global from '../../constants/baseurl'
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {

    }
    intercept(req : HttpRequest <any> , next : HttpHandler) : Observable<HttpEvent <any>>{
        
        if (req.url.includes('/login') || req.url.includes('forgotPassword') || req.url.includes('verifyOtpWithPassword') || req.url.includes('countries') || req.url.includes('states') || req.url.includes('/documents') || req.url.includes('services') || req.url.includes('/register') || req.url.includes('/spDocuments') || req.url.includes('/spServiceDocuments')|| req.url.includes('/serviceRequests') || req.url.includes('/srNote') || req.url.includes('/guest') || req.url.includes('/clientQaQuestionTime') || req.url.includes('/communityCertificates') || req.url.includes('/exportassets') || req.url.includes('/exportOwners'))  {
            return next.handle(req);
        }
        
         if (req.url.includes('/register') ||  req.url.includes('/updateSrQuotations') || req.url.includes('action=patch') || req.url.includes('/invoice') || req.url.includes('/payments') || req.url.includes('/openingBalance') || req.url.includes('/events') || req.url.includes('/import') || req.url.includes('/bulletinboards') || req.url.includes('/bulletinBoardComment') || req.url.includes('/bulletinBoards')) {
   
            req = req.clone({
                setHeaders: {
                    'Authorization': this.authService.getAuthToken()
                }
            })
        } else {
            req = req.clone({
                setHeaders: {
                    'Authorization': this.authService.getAuthToken(),
                    'content-type': 'application/json'
                }

            })
        }

        return next.handle(req).pipe(
        
            tap(x => x, err => {
                console.error(`Error performing request, status code = ${err.status}`);
                if (err.status == 401) {
                    // this.authService.handleExpiredAcessToken();
                    this.authService.logout();
                }
                if (err.status == 403) {
                    this.authService.logout();
                }
                if (req.url.includes('openingBalance') || req.url.includes('invoice') || req.url.includes('payments') || req.url.includes('communities/import')) {
                    if(err.status==422){
                        global.showSwalMessage(err.error.message,'error','Error');
                    }
                }
            })
        )

    }
}


