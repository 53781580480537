import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from '../../../app.service';
import { DefaultLayoutService } from '../default-layout.service';

@Component({
  selector: 'app-notifications-menu-list',
  templateUrl: './notifications-menu-list.component.html',
  styleUrls: ['./notifications-menu-list.component.scss']
})
export class NotificationsMenuListComponent implements OnInit {
  @Output() dismissNotification    = new EventEmitter()
  @Output() removeNotify    = new EventEmitter()

  constructor(public appService: AppService, private defaultLayoutService: DefaultLayoutService) {}

  ngOnInit() {
  }

  openNotification(event) {
    this.dismissNotification.emit(event)
  }

  removeNotification(event) {
    this.removeNotify.emit(event)
  }
  
}
