// export const baseUrl: string ='http://192.168.0.116:8089/eCommunityWebService/v3/'; 
// export const baseUrl: string ='http://13.228.67.35:8080/eCommunityWebService/v3/'; 
// export const baseUrl: string ='http://18.140.102.82:8080/eCommunityWebService/v3/'; 
export const baseUrl: string = 'http://13.228.67.35:8080/eCommunityWebService/v3/';
export const email: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const numberType: RegExp = /^[0-9]*$/;
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { FormGroup } from '@angular/forms';
export function showSwalMessage(text, type, title, showCancel = false) {
  return Swal.fire({
    title: title,
    text: text,
    icon: type,
    showCancelButton: showCancel,
    confirmButtonText: 'Ok',
    cancelButtonText: 'Cancel',
  })
}
export const validForms = ['docs', 'pdf', 'jpeg', 'png', 'jpg']
export const srUsers = ['Owner Living Inside', 'Tenant']

export function validateFile(name: string, include: string) {

  var ext = name.substring(name.lastIndexOf('.') + 1);

  if (include == 'all') {

    const validFileExt = ['docs', 'pdf', 'jpeg', 'png', 'jpg']
    return validFileExt.includes(ext.toLowerCase())

  } else if (include == 'excel') {

    const validFileExt = ['xlsx']
    return validFileExt.includes(ext.toLowerCase())

  }


}

export function downloadFile(fileBits: BlobPart[], fileName: string, extension: string, options?: FilePropertyBag) {
  const file = new File(fileBits, fileName + extension, options);
  saveAs(file);
}

export const resolvedSRStatus = 26;
export const closedSRStatus = 27;

export function forwardSRCheck(status, userGroup) {

}

export function durationBtw(startdate, enddate) {

  if (startdate != null && enddate != null) {
    var diff = Math.abs(enddate.getTime() - startdate.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays + 1
  }
  return ""
}

export function markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    if (control.controls) {
      this.markFormGroupTouched(control);
    }
  });
}

export function dateToEpoch(dateValue, time) {
  // var dateString = '17-09-2013 10:08'
  dateString = '17/09/2019 11.48'
  // this.dateValue 
  console.log(dateValue)
  var dateString = dateValue + " " + time
  var dateParts: Number;
  var dateTimeParts;
  var timeParts;

  dateTimeParts = dateString.split(' '),
    timeParts = dateTimeParts[1].split(':'),
    dateParts = dateTimeParts[0].split('/')
  let date = new Date(Number(dateParts[2]), parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1]);
  return date.getTime()

}


export function findInvalidControls(formGroup: FormGroup) {

  const invalid = [];
  const controls = formGroup.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  return invalid;
}

export function validateDates(startDate: string, endDate: string) {

  return ((startDate != null && endDate != null) && (endDate) < (startDate)) ? false : true;
}

