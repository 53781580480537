import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-core-toggle-switch',
  templateUrl: './core-toggle-switch.component.html',
  styleUrls: ['./core-toggle-switch.component.scss']
})
export class CoreToggleSwitchComponent implements OnInit {
  @Input () form_group      : FormGroup;
  @Input () form_field      : FormControlName;
  @Input () label           : String ;
  @Input () checked           : String ;
  constructor() { }

  ngOnInit() {
  }

}
