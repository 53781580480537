import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-core-notes',
  templateUrl: './core-notes.component.html',
  styleUrls: ['./core-notes.component.scss']
})
export class CoreNotesComponent implements OnInit {
  selectedItem: any;
  constructor(private routes:ActivatedRoute) {
    let value = this.routes.params.subscribe(params => {
      this.selectedItem = params.selecteditem;
    })
   }

  @Input() showFile        : boolean = true;
  @Input() attachNote      : boolean = false;
  @Output() addNotes       = new EventEmitter();
  @Input() notes           : any = [];
  @Input() status          : any;
  @Input() internalStatus  :any =[];
  @Input() buttonHide      : boolean = false;
  add                      : any;
  attachmentFile           : any;
  radioButtonValue         = 1;
  @Input()canAddNote       : boolean = true;
  @Output() change         = new EventEmitter();
  @Output() dropdownChange = new EventEmitter();
  @Input() dropdwonOptions = []
  toInternalNoteId = 0
  ngOnInit() {
    
    
  }
  ngOnChanges() {
    
    console.log(this.notes)
  }

  changeNoteFile($event){
    this.attachmentFile = $event.target.value;
    this.change.emit($event)
  }

  addNote(note,radioButtonValueId) {
    // this.addNotes.emit(note);
    this.add = '';
    this.attachmentFile = '';
    

    if (radioButtonValueId != 1) {
      let notes = {
        noteForAdd: note,
        internalId: radioButtonValueId,
        toInternalNoteId: this.toInternalNoteId
      }
      this.addNotes.emit(notes);
    }else{
      if(radioButtonValueId == undefined)
       this.addNotes.emit(note);
    else {
      let notes = {
        noteForAdd  : note,
        internalId  : radioButtonValueId,
        toInternalNoteId: this.toInternalNoteId
      }
      this.addNotes.emit(notes);
    }
    }

    // if(radioButtonValueId == undefined)
    //    this.addNotes.emit(note);
    // else {
    //   let notes = {
    //     noteForAdd  : note,
    //     internalId  : radioButtonValueId
    //   }
    //   this.addNotes.emit(notes);
    // }
  }
  onDropdownChange(e) {
    this.toInternalNoteId = e
    // this.dropdownChange.emit(e)
  }
}
