import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login-users/login/login.component';
import { TableComponentComponent } from './views/table-component/table-component.component';
import { UpdatePasswordComponent } from './views/login-users/update-password/update-password.component';
import { ForgotPasswordComponent } from './views/login-users/forgot-password/forgot-password.component';
import { FirstTimeLoginComponent } from './views/login-users/first-time-login/first-time-login.component';
import { PrivacyPolicyComponent } from './views/login-users/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './views/login-users/terms-and-conditions/terms-and-conditions.component'
import { LoginModule } from './views/login-users/login.module';
import { AuthRouteGuard } from './views/common/shared-service/auth-gurad';
import { RegisterSpComponent } from './views/register-sp/register-sp.component';
import { ContactUsComponent } from './views/login-users/contact-us/contact-us.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'Loading...',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: 'Loading...',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  }, 
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'first-time-login',
    component: FirstTimeLoginComponent
  },
  {
    path : 'register',
    children : [
      {
        path : 'register-sp',
        loadChildren : () => import('./views/register-sp/register-sp.module').then(m => m.RegisterSpModule)
      }
    ]
  },
  

  {
    path: 'login-users',
    component: LoginModule
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthRouteGuard],
    children: [

      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }, {
        path: 'table',
        component: TableComponentComponent
      }, {
        path: 'users',
        loadChildren: () => import('./views/all-users/user.module').then(m => m.UserModule)
      },
      {
        path: 'community',
        loadChildren: () => import('./views/community/community.module').then(m => m.CommunityModule)
      },
      {
        path: 'notification',
        loadChildren: () => import('./views/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'developer',
        loadChildren: () => import('./views/developer/developer.module').then(m => m.DeveloperModule)
      }, {
        path: 'service-provider',
        loadChildren: () => import('./views/service-provider/service-provider.module').then(m => m.ServiceProviderModule)
      }, {
        path: 'service-request',
        loadChildren: () => import('./views/service-request/service-request.module').then(m => m.serviceRequestModule)
      }, {
        path: 'inspections',
        loadChildren: () => import('./views/inspections/inspections.module').then(m => m.inspectionsModule)
      },
      {
        path: 'update-password',
        component: UpdatePasswordComponent
      }, {
        path: 'risk',
        loadChildren: () => import('./views/risk-dashboard/risk-module').then(m => m.RiskModule)
      },
      {
        path: 'tasks',
        loadChildren: () => import('./views/tasks/tasks.module').then(m => m.TasksModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./views/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('./views/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./views/forms/forms.module').then(m => m.FormModule)
      },{
        path: 'events',
        loadChildren: () => import('./views/events/events.module').then(m => m.EventsModule)
      },{
        path: 'bulletin-boards',
        loadChildren: () => import('./views/bulletin-boards/bulletin-boards.module').then(m => m.BulletinBoardsModule)
      },{
        path: 'collections',
        loadChildren: () => import('./views/collections/collections.module').then(m => m.CollectionsModule)
      }, {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
      },

      {
        path:'guest-registration',
        loadChildren: () => import('./views/guest-registration/guest-registration.module').then(m=>m.GuestRegistrationModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
