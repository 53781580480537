import { Component } from '@angular/core';
import {AuthService} from '../common/shared-service/auth-service';
import { Router } from '@angular/router';

@Component({
  templateUrl: '500.component.html'
})
export class P500Component {

  hide = false;
  constructor(private authService :AuthService,private router:Router) { 

    if(authService.getAuthToken())
      this.router.navigate(['/dashboard']);
    else 
      this.router.navigate(['/login']);
  }
}
