import { Component, OnInit,Input } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-core-text-box',
  templateUrl: './core-text-box.component.html',
  styleUrls: ['./core-text-box.component.scss']
})
export class CoreTextBoxComponent implements OnInit {
  @Input () form_group      : FormGroup;
  @Input () form_field      : FormControlName;
  @Input () label           : String ;
  @Input () placeHolder     : String;
  @Input () type            : String = "text";
  @Input () required        : boolean  = true;
  @Input () disable         : boolean;
  @Input () readonly        : string = "";
  @Input () requiredError   : string;
  @Input () validationError : string;
  // @Input () disableClass    : string = "";
  
  constructor() { }

  ngOnInit() {
   
  }

}
