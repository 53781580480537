import { Component, OnInit } from '@angular/core';
import { GuestService } from '../../guest-registration/guest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as global from '../../constants/baseurl';
import { Location } from '@angular/common';
import { DateToEpochPipe } from '../../common/pipes/date-to-epoch.pipe';

@Component({
  selector: 'app-guest-details',
  templateUrl: './guest-details.component.html',
  styleUrls: ['./guest-details.component.scss']
})
export class GuestDetailsComponent implements OnInit { 
  guestDetailsForm:  FormGroup;
  header          : string   = 'Details';
  disableBtn      : boolean  = false;
  reportBtn       : boolean  = false;
  profilePhoto    : boolean  = false;
  _convertDate    : DateToEpochPipe;
  userdata; clientId; action; guestId; guestDetails; community; name; phoneNo; unit; dateOfVisit; vehicleNumber; idType; idNumber; photoId; checkInTime; remarks; companyName; companyPhoneNo; materialsIn; materialsOut; purposeOfVisit; noc_file; guestType; noc_id; checkOutTime;

  constructor(private location: Location,private fbuilder : FormBuilder, private route:Router, private guestService: GuestService, private router: ActivatedRoute) {
    this._convertDate = new DateToEpochPipe();
    this.guestDetailsForm = this.fbuilder.group({
      'materialsOut'             : [""],
    },{updateOn: 'blur'},
    
    )
   }

  ngOnInit() {
    this.userdata = JSON.parse(localStorage.getItem('data'));
    this.clientId = this.userdata.id;
    let value = this.router.params.subscribe( params => {
      this.action    = params.action 
      this.guestId = params.id
    });

    this.loadData()

    if(this.action == "View"){
      this.guestDetailsForm.disable()
      this.disableBtn = true
    }
    else if(this.action == "view"){
      this.guestDetailsForm.disable();
      this.reportBtn = true;
      this.disableBtn = false
    }
    else{
      this.disableBtn = false
    }
  }

  loadData(){
    var timestamp;
    var checkOutTimestamp;
    this.guestService.getGuestById(this.guestId).subscribe(response => {
      if(response != null){
        this.guestDetails = response['data']; 
        if(this.guestDetails.checkInTime != undefined) {
          timestamp = this._convertDate.convertDateToDDMMYY(this.guestDetails.checkInTime);
          this.checkInTime = timestamp + " " + (new Date(this.guestDetails.checkInTime)).toLocaleTimeString();
        } else {
          this.checkInTime = ""
        }

        if(this.guestDetails.checkOutTime != undefined) {
          checkOutTimestamp =  this._convertDate.convertDateToDDMMYY(this.guestDetails.checkOutTime);
          this.checkOutTime = checkOutTimestamp+ " " + (new Date(this.guestDetails.checkOutTime)).toLocaleTimeString();
        } else {
          this.checkOutTime = ""
        }
        
        this.guestType = this.guestDetails.guestType != undefined ? this.guestDetails.guestType : ""
        this.name = this.guestDetails.name != undefined ? this.guestDetails.name : ""
        this.phoneNo = this.guestDetails.phoneNo != undefined ? this.guestDetails.phoneNo : ""
        this.companyName = this.guestDetails.companyName != undefined ? this.guestDetails.companyName : ""
        this.companyPhoneNo = this.guestDetails.companyPhoneNo != undefined ? this.guestDetails.companyPhoneNo : ""
        this.dateOfVisit = this.guestDetails.dateOfVisit != undefined ? this.guestDetails.dateOfVisit : ""
        this.vehicleNumber = this.guestDetails.vehicleNumber != undefined ? this.guestDetails.vehicleNumber : ""
        this.idType = this.guestDetails.idType != undefined ? this.guestDetails.idType : ""
        this.idNumber = this.guestDetails.idNumber != undefined ? this.guestDetails.idNumber : ""
        this.community = this.guestDetails.unit.building != undefined || this.guestDetails.unit.building.community != undefined || this.guestDetails.unit.building.community.name != undefined ? this.guestDetails.unit.building.community.name : ""
        this.unit = this.guestDetails.unit != undefined ? this.guestDetails.unit.name : ""
        this.materialsIn = this.guestDetails.materialsIn != undefined ? this.guestDetails.materialsIn : ""
        this.materialsOut = this.guestDetails.materialsOut != undefined ? this.guestDetails.materialsOut : ""
        this.remarks = this.guestDetails.remarks != undefined ? this.guestDetails.remarks : ""
        this.purposeOfVisit = this.guestDetails.purposeOfVisit != undefined ? this.guestDetails.purposeOfVisit : ""
        this.noc_id = this.guestDetails.nocReferenceNumber != undefined ? this.guestDetails.nocReferenceNumber : ""

        this.guestDetailsForm.controls['materialsOut'].setValue(this.materialsOut)

        if(this.guestDetails.documentList != undefined){
          this.guestDetails.documentList.forEach(documents => {
            if(documents.documentType.typeName == "Id Photo"){
              this.photoId = documents.documentName
              this.profilePhoto = true
            }
            if(documents.documentType.typeName == "NOC"){
              this.noc_file = documents.documentName
            }
          })
        }
        
      }
    })
  }

  checkOut(formValue) {
    let formData : FormData  = new FormData();
    if(this.guestDetailsForm.invalid){
      this.markFormGroupTouched(this.guestDetailsForm)
    }else{
      formData.append('materialsOut',formValue.materialsOut);
      this.guestService.guestCheckOut(this.clientId,this.guestId,formData).subscribe(info => {
      }, (error) => {
        global.showSwalMessage("Something went wrong!", 'error', 'Error');
      }, () => {
        global.showSwalMessage("Updated successfully", 'success', 'Success');
        this.location.back()
        // this.route.navigateByUrl('/guest-registration/guest-registration/guest-listing')
      })
    }
  }

  cancel(event){
    this.location.back()
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
