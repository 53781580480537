import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../../constants/baseurl';
import { RequestOptions, ResponseContentType } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient) { }
  getAllCommunity() {
    return this._http.get(global.baseUrl + 'communities').pipe()
  }
  getUnitsBalanceAmount(id) {
    return this._http.get(global.baseUrl + 'paymentsummary/' + [id]).pipe()
  }
  paymentInvoicesUpload(formData,clientId) {
    return this._http.post(global.baseUrl + 'clients/'+clientId+ '/invoice', formData).pipe()
  }
  paymentUpload(formData,clientId) {
    return this._http.post(global.baseUrl + 'clients/'+clientId+ '/payments', formData).pipe()
  }
  paymentOpeningBalanceUpload(formData) {
    return this._http.post(global.baseUrl + 'openingBalance', formData).pipe()
  }

  getUnitsByid(id) {
    return this._http.get(global.baseUrl + 'communities/' + [id] + '/units').pipe()
  }
  registerUser(formData) {
    return this._http.post(global.baseUrl + 'register', formData).pipe()
  }
  getAllDesignations(type) {
    return this._http.get(global.baseUrl + 'userGroups?groupType=' + type).pipe()

  }

  getUserDetails(userId) {
    return this._http.get(global.baseUrl + 'clients/' + userId).pipe();
  }

  updateUserDetails(userId, data) {
    return this._http.post(global.baseUrl + 'clients/' + userId + '?action=patch', data).pipe();

  }

  deleteAdminUser(userId) {
    return this._http.delete(global.baseUrl + 'clients/' + userId).pipe();
  }

  //For AccountStatement for community
  accountStatement(communityId, paymentDate) {
    return this._http.get(global.baseUrl + 'communities/' + communityId + '/accountStatement?date=' + paymentDate).pipe();
  }
}
