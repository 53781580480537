import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject} from 'rxjs';
import { baseUrl } from '../constants/baseurl';
import { map } from 'rxjs/operators';
import { RequestOptions, ResponseContentType } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class CommunityService {
   
  violationInfo = new BehaviorSubject('');
  violationInfoStream = this.violationInfo.asObservable();

  constructor(private http: HttpClient) { }

  updateviolationInfo(data: any){
    this.violationInfo.next(data)
  }

  getCommunity(): Observable<any>{
    return this.http.get(baseUrl+'communities').pipe()
  }
  getUnitByCommunity(communityId): Observable<any>
  {
    return this.http.get(baseUrl+'communities/'+communityId+'/units').pipe()
  } 
  addCommunity(id,data :any): Observable<any>{
    return this.http.post(baseUrl+'developers/'+id+'/communities',data).pipe()
  } 
  getCommunityById(communityId): Observable<any>{
    return this.http.get(baseUrl+'communities/'+[communityId]).pipe()
  } 
  editCommunity(developerId,data,communityId): Observable<any>{
    return this.http.patch(baseUrl+'developers/'+developerId+'/communities/'+communityId,data).pipe()
  } 
  getAllInternalUsers(): Observable<any>
  {
    return this.http.get(baseUrl+'admins').pipe();
  }
  getAssetList(communityId):Observable<any>
  {
    return this.http.get(baseUrl+'communities/'+communityId+'/assets').pipe()
  }
  getAssetCategory():Observable<any>
  {
    return this.http.get(baseUrl+'assetCategories').pipe()
  }
  createAsset(data,communityId):Observable<any>
  {
    return this.http.post(baseUrl+'communities/'+communityId+'/assets',data).pipe()
  }
  getAssetById(assetId) : Observable<any>
  {
    return this.http.get(baseUrl+'assets/'+assetId).pipe()
  }
  editAsset(communityId,assetId,data):Observable<any>
  {
    return this.http.patch(baseUrl+'communities/'+communityId+'/assets/'+assetId,data).pipe()

  }
  deleteAsset(communityId,assetId)
  {
    return this.http.delete(baseUrl+'communities/'+communityId+'/assets/'+assetId).pipe()
  }
  assetBulkUpload(communityId,data) : Observable<any>
  {
    return this.http.post(baseUrl+'communities/'+communityId+'/exportassets',data).pipe()
  }
  viewSRHistory(assetId):Observable<any>
  {
    return this.http.get(baseUrl+'assets/'+assetId+'/srHistories').pipe()
  }
  
  // committee members 
  getCommitteeMembers(communityId):Observable<any>{
    return this.http.get(baseUrl+'communities/'+communityId+'/committeemembers').pipe()
  }
  addCommitteeMember(id,data):Observable<any>{
    return this.http.post(baseUrl+'communities/'+id+'/committeemembers',data).pipe()
  }
  getCommitteePosition():Observable<any>{
    return this.http.get(baseUrl+'committeememberposition').pipe()
  }
  deleteCommitteeMember(memberId){
    return this.http.delete(baseUrl+'committeemembers/'+memberId).pipe()
  }
  editCommitteeMember(id,data){
    return this.http.patch(baseUrl+'committeemembers/'+id,data).pipe()
  }
  searchCommitteemember(communityId,searchData){
    if(searchData.match("@")){
      return this.http.get(baseUrl+'communities/'+communityId+'/clients?phoneNumber=&email='+[searchData]).pipe()
    }else{
      return this.http.get(baseUrl+'communities/'+communityId+'/clients?phoneNumber='+[searchData]+'&email=').pipe() 
    }
  }

  searchMember(communityId,searchData){
      return this.http.get(baseUrl+'client?email='+searchData).pipe()
    
  }

  //create AMC
  createAMC(data,communityId){
    return this.http.post(baseUrl+'communities/'+communityId+'/amc',data).pipe()
  }
  getAllVendors(clientId,communityId,serviceId)
  {
    return this.http.get(baseUrl+'clients/'+clientId+'/communities/'+communityId+'/services/'+serviceId).pipe()
  }
  getAllVendorsforDocuments(communityId)
  {
    return this.http.get(baseUrl+'communities/'+communityId+'/serviceProviders').pipe()
  }
  getAllFrequencyList()
  {
    return this.http.get(baseUrl+'amcFrequencies').pipe()
  }
  getAMCList(communityId,year){
    return this.http.get(baseUrl+'communities/'+communityId+'/amcsForAnYear?year='+year).pipe()
  }
  getAMCSchedule(communityId,year){
    return this.http.get(baseUrl+'communities/'+communityId+'/amcschedules?year='+year).pipe()
  }
  deleteAMC(amcId){
    return this.http.delete(baseUrl+'amcs/'+amcId).pipe()
  } 
  deleteAMCSchedule(scheduleId){
    return this.http.delete(baseUrl+'amc/'+scheduleId+'/schedule').pipe()
  }
  createAMCSchedule(amcId,data){
    return this.http.post(baseUrl+'amc/'+amcId+'/schedule',data).pipe()
  }
  updateAMC(communityId,amcId,data){
    return this.http.patch(baseUrl+'communities/'+communityId+'/amc/'+amcId,data).pipe()
  }
  updateAMCSchedule(clientId,amcScheduleId,data){
    return this.http.patch(baseUrl+'clients/'+clientId+'/amcSchedules/'+amcScheduleId,data).pipe()
  }
  exportAMC(communityId,year){
    return this.http.get(baseUrl+'communities/'+communityId+'/amcschedules/export?year='+year).pipe()
  }

  getServiceCategory(){
    return this.http.get(baseUrl+'servicecategories?groupType=Internal User').pipe()
  }
  getServicesbyId(servicecategoryid){
    return this.http.get(baseUrl+'servicecategories/'+servicecategoryid+'/services').pipe()
  }
  getVendorsbyService(clientId,communityId,serviceId){
    return this.http.get(baseUrl+'clients/'+clientId+'/communities/'+communityId+'/services/'+ serviceId + '/amc').pipe()
  } 

  editBasicInfo(communityId,data): Observable<any>{
    return this.http.patch(baseUrl+'communities/'+communityId,data).pipe()
  } 
  
  unitBulkUpload(formDataFile) {
    return this.http.post(baseUrl+'communities/import',formDataFile).pipe()
  }
  unitOwnerUpload(clientId,formDataFile) {
    return this.http.post(baseUrl+'clients/'+clientId+'/unitOwners/import',formDataFile).pipe()
  }
  DeveloperUpload(clientId,formDataFile) {
    return this.http.post(baseUrl+'clients/'+clientId+'/developers/import',formDataFile).pipe()
  }
  addBuilding(communityId,buidingInfo){
    return this.http.post(baseUrl+'communities/'+communityId+'/buildings',buidingInfo).pipe()
  }
  editBuilding(buildingId,buidingInfo){
    return this.http.patch(baseUrl+'buildings/'+buildingId,buidingInfo).pipe()
  }
  buildingList(communityId){
    return this.http.get(baseUrl+'communities/'+communityId+'/buildings').pipe()
  }

  exportToExcel(communityId) {
    const options = new RequestOptions({
      responseType: ResponseContentType.Blob
  });
    return this.http.get(baseUrl+'communities/'+communityId+'/export').pipe()
  }
  getAllCertificates(communityId) {
    return this.http.get(baseUrl+'communities/'+communityId+'/communityCertificates').pipe()
  }    
  //Documents
  getAllDocumentTypes()
  {
    return this.http.get(baseUrl+'documentType').pipe()
  }
  createDocument(data,communityId)
  {
    return this.http.post(baseUrl+'communities/'+communityId+'/communityCertificates',data).pipe()
  }
  getAssetByassetcategory(assetcategoryId)
  {
    return this.http.get(baseUrl+'assetCategories/'+assetcategoryId+'/assets').pipe()
  }  
  addSecuritybyId(communityId,data) {
    return this.http.post(baseUrl+'communities/'+communityId+'/securities',data).pipe()
  }
  deleteDocument(documentId)
  {
    return this.http.delete(baseUrl+'communityCertificates/'+documentId).pipe()
  }

  getDocumentsById(documentId)
  {
    return this.http.get(baseUrl+'communityCertificates/'+documentId).pipe()
  }

  getAllSecurityList(communityId) {
    return this.http.get(baseUrl+'communities/'+communityId+'/securities').pipe()
  }

  editDocuments(documentId,data) {
    return this.http.patch(baseUrl+'communityCertificates/'+documentId,data).pipe()
  }

  deleteSecrityFromCommunitybyId(shiftId) {
    return this.http.delete(baseUrl+'securityShifts/'+shiftId).pipe()
  }

  editSecurity(communityId,shiftId,formData) {
    return this.http.patch(baseUrl+'communities/'+communityId+'/securityShift/'+shiftId,formData).pipe()
  }

  // risk projectory 
  getAllriskCategories(){
    return this.http.get(baseUrl+'riskCategories').pipe()
  }
  getRiskProbability(){
    return this.http.get(baseUrl+'riskProbabilities').pipe()
  }
  getRiskBusinessImpacts(){
    return this.http.get(baseUrl+'riskBusinessImpacts').pipe()
  }
  getRiskStatuses(){
    return this.http.get(baseUrl+'riskStatuses').pipe()
  }
  getUsers(communityId){
    return this.http.get(baseUrl+'communities/'+communityId+'/internalUsers').pipe()
  }
  createRisk(communityId,data){
    return this.http.post(baseUrl+'communities/'+communityId+'/riskRegistries',data).pipe()
  }
  getAllRisksByCommunity(communityId){
    return this.http.get(baseUrl+'communities/'+communityId+'/riskRegistries').pipe()
  }
  updateRiskById(riskId,data) {
    return this.http.patch(baseUrl+'riskRegistries/'+riskId,data).pipe()
  }
  deleteRiskById(riskId){
    return this.http.delete(baseUrl+'riskRegistries/'+riskId).pipe()
  }
  getMultipleRiskRegistries(data){
    return this.http.post(baseUrl+'communities/riskRegistries',data).pipe()
  }
  getRiskDashboard(data){
    return this.http.post(baseUrl+'riskRegistryDashBoard',data).pipe()
  }

  //meeting
  addMeeting(data :any): Observable<any>{
    return this.http.post(baseUrl+'meetings',data).pipe()
  } 
  getCategories(): Observable<any>{
    return this.http.get(baseUrl+'listMeetingCategory').pipe()
  }
  getMeetingByCommunityId(id): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/meetings').pipe()
  } 
  getMeetingById(id): Observable<any>{
    return this.http.get(baseUrl+'meetings/'+id).pipe()
  }

  editMeeting(id,data): Observable<any>{
    return this.http.patch(baseUrl+'meetings/'+id,data).pipe()
  } 

  deleteAgendaById(agendaId)
   {
    return this.http.delete(baseUrl+'agenda/'+agendaId).pipe()
   }

   deleteSubAgendaById(subagendaId)
   {
    return this.http.delete(baseUrl+'subAgenda/'+subagendaId).pipe()
   }
   getMeetingInvitees(id): Observable<any>{
    return this.http.get(baseUrl+'meetings/'+id+'/meetingInvitees').pipe()
  }
  updateMeetingInvitees(data){
    return this.http.patch(baseUrl+'meetingInvitees',data).pipe()
  }
  getAgendaByMeetingId(id){
    return this.http.get(baseUrl+'meetings/'+id+'/agenda').pipe()
  }
  addMomAgenda(meetingId,data){
    return this.http.post(baseUrl+'meetings/'+meetingId+'/momAgenda',data).pipe()
  }
  addMomSubAgenda(meetingId,momAgendaId,data){
    return this.http.post(baseUrl+'meetings/'+meetingId+'/momAgendas/'+momAgendaId+'/momSubAgenda',data).pipe()
  }
  addMomSubAction(momAgendaId,momSubAgendaId,data){
    return this.http.post(baseUrl+'momAgendas/'+momAgendaId+'/momSubAgendas/'+momSubAgendaId+'/momAction',data).pipe()
  }
  updateMeetingStatus(meetingId,data){
    return this.http.patch(baseUrl+'meetings/'+meetingId+'/meetingStatus',data).pipe()
  }
  sendMeeting(meetingId){
    return this.http.get(baseUrl+'meetings/'+meetingId+'/meetingPdf').pipe()
  }
  previewMeeting(meetingId){
    return this.http.get(baseUrl+'meetings/'+meetingId+'/meetingAgenda.pdf').pipe()
  }
  sendMom(meetingId){
    return this.http.get(baseUrl+'meetings/'+meetingId+'/minutesOfMeetingPdf').pipe()
  }
  previewMom(meetingId){
    return this.http.get(baseUrl+'meetings/'+meetingId+'/minutesOfMeeting.pdf').pipe()
  }
 
  getMomagendabyId(Id){
    return this.http.get(baseUrl+'momagenda/'+Id).pipe()
  }
  getAllMomagendabymeetingId(meetingId){
    return this.http.get(baseUrl+'meetings/'+meetingId+'/momagendas').pipe()
  }
  editMomAgenda(momAgendaId,data){
    return this.http.patch(baseUrl+'momAgendas/'+momAgendaId,data).pipe()
  }
  deleteMomSubagenda(momSubagendaId){
    return this.http.delete(baseUrl+'momSubAgenda/'+momSubagendaId).pipe()
  }

  // Parking
  addSlotByUnit(data){
    return this.http.post(baseUrl+'parkingSlots',data).pipe()
  }
  getSlotsByCommunity(community_id) {
    return this.http.get(baseUrl+'communities/'+community_id+'/parkingSlots').pipe()
  }  
  getSlotById(id){
    return this.http.get(baseUrl+'parkingSlots/'+id).pipe()
  }
   editSlot(id,formData) {
    return this.http.patch(baseUrl+'parkingSlot/'+id,formData).pipe()
  }
  unAssignSlot(Id){
    return this.http.delete(baseUrl+'parkingSlot/'+Id).pipe()
  }
  deleteViolatedSlot(Id){
    return this.http.delete(baseUrl+'parkingSlotViolation/'+Id).pipe()
  }
  getViolatedSlotsByCommunity(community_id){
    return this.http.get(baseUrl+'communities/'+community_id+'/violatedparkingSlots').pipe()
  }
  addViolatedSlots(data){
    return this.http.post(baseUrl+'parkingSlotViolation',data).pipe()
  }
  getFormByUnit(unit_id,parkingSlot_id){
    return this.http.get(baseUrl+'units/'+unit_id+'/parkingSlots/'+parkingSlot_id+'/form').pipe()
  }
  postCreateMoM(data){
    return this.http.post(baseUrl+'createMom',data).pipe()
  }
  getUnitByBuilding(communityId,buildingId){
    return this.http.get(baseUrl+'communities/'+communityId+'/buildings/'+buildingId+'/units').pipe()
  }
  getAllUserGroup(){
    return this.http.get(baseUrl+'userGroups?groupType=Unit User').pipe()
  }

  //unit owners 
  getMollakUnitowners(communityId) {
    return this.http.get(baseUrl+'communities/'+communityId+'/mollakIntegration/exportOwners', { responseType: 'blob'}).pipe()
  }

  getCommunityUnitowners(communityId) {
    return this.http.get(baseUrl+'communities/'+communityId+'/exportOwners', { responseType: 'blob'}).pipe()
  }
}





