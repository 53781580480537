import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';
@Component({
  selector: 'app-core-datalist',
  templateUrl: './core-datalist.component.html',
  styleUrls: ['./core-datalist.component.scss']
})
export class CoreDatalistComponent implements OnInit {

  @Input () list_type               : string ;
  @Input () dataList                : any;
  @Input () form_group              : FormGroup;
  @Input () form_field              : FormControlName;
  @Input () label                   : String ;
  @Input () requiredError           : string;
  @Input () validationError         : string;
  @Input () required                : boolean;
  @Input () placeHolder             : String;
  @Output() OndataListChange        = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  changeDataList(value){
    this.OndataListChange.emit(value)
  }

}
