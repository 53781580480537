import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ForgotPasswordService } from '../services/forgot-password.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  error: boolean;
  userData: any;
  title = "Forgot Password";
  isForgotPwd: boolean = false;
  timeInterval: boolean = false;
  submitted: boolean = false;
  userId
  constructor(private FormBuilder: FormBuilder, private forgotPwdService: ForgotPasswordService, private router: Router) {
    this.forgotPasswordForm = this.FormBuilder.group({
      loginId: ['', [Validators.required]],
    });
  }
  ngOnInit() {
  }
  onSubmit() {

    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      this.error = true;
      return;
    }
    if (this.forgotPasswordForm.valid) {
      this.forgotPwdService.forgotPassword(this.forgotPasswordForm.value.loginId).subscribe(response => {

      }, (error) => {
        this.error = true
      }, () => {
        this.userId = this.forgotPasswordForm.value.loginId;
        this.isForgotPwd = true;
        this.timeInterval = true;
        setTimeout(() => {
          this.timeInterval = false;
        }, 3000)
      })
    }
  }

  // convenience getter for easy access to form fields

  get f() { return this.forgotPasswordForm.controls; }
  
  redirect($event) {
    if ($event) {
      localStorage.clear()
      this.router.navigateByUrl('/login')
    }
     
  }
}
