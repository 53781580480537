import { Component, OnInit,Input } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-core-timepicker',
  templateUrl: './core-timepicker.component.html',
  styleUrls: ['./core-timepicker.component.scss']
})
export class CoreTimepickerComponent implements OnInit {
@Input ()required  : boolean = false;
@Input () form_group      : FormGroup;
@Input () form_field      : FormControlName;
@Input() requiredError     : string;
@Input() validationError  : string;
isMeridian = false;
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(){
  }
  
}
