import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { AllUsersService } from '../../all-users.service';

@Component({
  selector: 'app-reason-for-rejection',
  templateUrl: './reason-for-rejection.component.html',
  styleUrls: ['./reason-for-rejection.component.scss']
})
export class ReasonForRejectionComponent implements OnInit {
  @ViewChild('primaryModal', { static: false }) public primaryModal: ModalDirective;

  reasonForRejection = "";
  unit_id            : any;
  submitted                = false;
  @Output() modalData  = new EventEmitter();
  constructor(private auth: AllUsersService, private loc: Location) { }

  ngOnInit() {

  }

  showPopup(id) {

    this.reasonForRejection = '';
    this.unit_id   = id
    this.submitted = false
    this.primaryModal.show()
  }

  triggerBtnClick(reason) {   

    this.submitted = true
    if (reason != '') {

      this.modalData.emit(this.reasonForRejection)
    }
    
  }

  dismissModal() {

    this.reasonForRejection = '';
    this.primaryModal.hide()
  }
}
