import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-core-timer',
  templateUrl: './core-timer.component.html',
  styleUrls: ['./core-timer.component.scss']
})
export class CoreTimerComponent implements OnInit {

  @Input() label: string = "Time Left";
  // @Input() timerCount;
  // timerCount = 90;
  constructor() { }

  ngOnInit() {
  }

}
