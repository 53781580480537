import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MessagingService } from './views/messaging.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html'  
              
})
export class AppComponent implements OnInit {
  message;
  // {{ message  | async | json }}
  // @Component({
  //   // tslint:disable-next-line
  //   selector: 'body',
  //   template: `<router-outlet></router-outlet>
              
  //               `
  // })

  constructor(private router: Router,private messagingService: MessagingService) { }

  ngOnInit() {
    // this.messagingService.requestPermission()
    // this.messagingService.receiveMessage();

    // if( this.messagingService.currentMessage.thrownError != null){
    //   this.message = this.messagingService.currentMessage
    // }
    // else{
    //   this.message = '';
    // }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

  }
}
