import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { baseUrl } from './views/constants/baseurl';

@Injectable()
export class AppService {

  notificationData = new BehaviorSubject([]);
  notify = this.notificationData.asObservable();
  notifyCount = new BehaviorSubject(0);
  notifyC = this.notifyCount.asObservable();

  constructor(private http: HttpClient) { 
  }

  updateNotificationData(data: any) {
		this.notificationData.next(data);
  }
  updateNotifyCount(data: any) {
		this.notifyCount.next(data);
  }
}
