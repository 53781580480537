import { Component, OnInit, Input, AfterContentInit, ViewChild, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { ColumnSettings } from '../models/columnSettings';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Observable } from 'rxjs';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { event } from 'jquery';
@Component({
  selector: 'app-core-table',
  templateUrl: './core-table.component.html',
  styleUrls: ['./core-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreTableComponent implements OnInit, AfterContentInit {
  @Input() isHeader: boolean = true;

  @Input() settings: any[]
  @Input() records: any[];
  @Input() tableDetails: any;
  @Input() servicePagination: boolean;
  @Input() header: string = "";
  @Input() tblClass: string = "";
  @Input() splClass: string = "";
  @Input() cardLabel = "card";
  @Input() textareaHead = "Remarks";
  @Input() className: string;
  @Input() remarksSection: boolean = false;
  @Input() fileSection: boolean = false;
  @Input() redirectSection: boolean = false;
  @Input() slnoSection: boolean = false;
  @Input() typeVal;
  @Input() recordList: Observable<any[]>
  @Output() actionBtnClicked = new EventEmitter();
  @Output() textClick = new EventEmitter();
  @Output() changeFiles = new EventEmitter();
  keys: string[];
  j = 98;
  startPage = 1
  endPage = 10
  page: number;
  attachmentFile: any;
  tableHeader: ColumnSettings[];
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  @Output() tableEmitter = new EventEmitter();
  dtOptions: DataTables.Settings = {};
  @Output() change = new EventEmitter();
  // contentArray: string[] = new Array(50).fill('');
  @Output() pageChangeEvent = new EventEmitter();
  @Output() searchEvent = new EventEmitter();
  @Output() sortEvent = new EventEmitter();
  pageSize: number = 10;
  pageNum: number = 0;
  returnedArray: string[];
  searchValue: any
  showBoundaryLinks: boolean = true;
  showDirectionLinks: boolean = true;
  disablePagination: boolean = true;
  Loading: boolean = false
  headersWithSort: any[];
  constructor() { }
  selectAll = false;
  ngOnInit() {
    if (this.servicePagination) {
      this.dtOptions = {
        paging: false,
        info: false,
        searching: false,
        ordering: false
      }
      this.Loading = false;
      this.headersWithSort = ["Unit", "Ticket number", "Description", "Community", "Service category", "Assigned Group"]
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dtElement.dtInstance != undefined) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        //  dtInstance.destroy();
        // this.dtTrigger.next()
        dtInstance.clear()
      });
    }

    if (this.servicePagination && this.tableDetails !== null) {
      this.recordCount()
      this.Loading = false;
    }
  }

  searchData(events) {
    let value = events.target.value
    this.searchValue = value ? value : ''
    if (!value) {
      this.searchEvent.emit(value)
      this.Loading = true;
    }
  }

  findTableData() {
    let value = this.searchValue ? this.searchValue : ''
    if (value) {
      this.Loading = true;
      this.searchEvent.emit(value)
    }
  }
  clearTableData() {
    if (this.searchValue) {
      this.searchValue = "";
      let value = (<HTMLInputElement>document.getElementById("search")).value = ""
      this.Loading = true;
      this.searchEvent.emit(value)
    }
  }

  pageNumberChanged(event) {
    this.pageNum = (event.page - 1)
    let pageNumber = this.pageNum
    let pageSize = event.itemsPerPage
    this.pageChangeEvent.emit({ pageNumber, pageSize, type: "pageNum" })
    this.recordCount(event)
  }

  recordCount(event?) {
    let totalItemsCount = this.tableDetails.totalElements;
    let numberOfItemsPerPage = event && event.itemsPerPage ? event.itemsPerPage : this.tableDetails.size;
    let page = event && event.page > 0 ? event.page : this.tableDetails.number + 1;
    this.startPage = (page - 1) * numberOfItemsPerPage + 1;
    this.endPage = Math.min(this.startPage + numberOfItemsPerPage - 1, totalItemsCount);

  }
  setItemsPerPages(items) {
    if (this.records.length > 0) {
      this.pageSize = parseInt(items)
      let pageNumber = this.pageNum;
      let pageSize = this.pageSize
      let itemsPerPage = parseInt(items)
      let page = pageNumber + 1
      let event = { page, itemsPerPage }
      this.pageChangeEvent.emit({ pageNumber, pageSize })
      this.recordCount(event)
    }
  }

  sortTableData(sortOption, columnName, event) {
    let sortField;
    switch (columnName) {
      case "Unit":
        sortField = "unit.name"
        break;
      case "Ticket number":
        sortField = "ticketNo"
        break;
      case "Description":
        sortField = "description"
        break;
      case "Community":
        sortField = "community.name"
        break;
      case "Assigned Group":
        sortField = "userGroup.groupName"
        break;
      case "Service category":
        sortField = "serviceCategory.category"
        break;
      default:
    }
    this.sortEvent.emit({ sortField, sortOption })
  }

  triggerBtnClick(type, record, value) {
    // console.log("record",record)
    this.actionBtnClicked.emit({ type: type, records: record, radioVal: value })
  }

  textareaClick(type, record, val) {
    this.textClick.emit({ type: type, records: record, value: val })
  }
  changeNoteFile($event) {
    this.attachmentFile = $event.target.value;
    console.log("attach file", this.attachmentFile)

    this.change.emit($event)
  }
  changeFile(event, record) {
    this.attachmentFile = event.target.value;
    // console.log("record",record)
    // console.log("attach files",this.attachmentFile)
    this.changeFiles.emit({ event: event, records: record })
  }

  triggerCkeckboxClick(type, record, value) {

    let chkbox = <HTMLInputElement>document.getElementById(record.id);
    if (chkbox.checked) {
      value = 'yes';
      this.triggerBtnClick(type, record, value);
    }
    else {
      value = 'no';
      this.triggerBtnClick(type, record, value);
    }
  }

  onSelectAll(type, records, value) {

    let selectAllCheck = <HTMLInputElement>document.getElementById('selectall');
    records.forEach(record => {
      let chkbox = <HTMLInputElement>document.getElementById(record.id);
      chkbox.checked = selectAllCheck.checked
      record.checked = selectAllCheck.checked
    })
    this.triggerBtnClick(type, records, 'selectAll');

  }

  ngAfterViewInit(): void { this.dtTrigger.next(); }

  ngAfterContentInit() {
    if (this.records.length > 0) {
      this.keys = Object.keys(this.records[0]);
      if (this.settings) {
        this.tableHeader = this.settings
      } else {
        this.tableHeader = Object.keys(this.records[0])
          .map(key => {
            return {
              primaryKey: key,
              header: key.slice(0, 1).toUpperCase() +
                key.replace(/_/g, ' ').slice(1)
            }
          });
      }
    }
    else {
      if (this.settings) { // when settings provided
        this.tableHeader = this.settings;
      }
    }

  }
  welcome(key) {
    this.tableEmitter.emit(key);
  }
}
