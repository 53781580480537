import { Component, OnInit ,Input,ViewChild,SimpleChanges, OnChanges, Output, EventEmitter} from '@angular/core';
import { BsDatepickerDirective, } from 'ngx-bootstrap/datepicker/bs-datepicker.component';
import { FormGroup, FormControlName } from '@angular/forms';
@Component({
  selector: 'app-core-datepicker',
  templateUrl: './core-datepicker.component.html',
  styleUrls: ['./core-datepicker.component.scss']
})
export class CoreDatepickerComponent implements OnInit,OnChanges {

  @Input () label           : String;
  @Input () placeHolder     : String;
  @Input () form_group      : FormGroup;
  @Input () form_field      : FormControlName;
  @Input () required        = true;
  @Input () isDynamicMinDate : boolean = false
  @Input () public disabledValue: boolean;
  @Input () minDateValue     : any;
  @Input () maxDateValue     : any;
  @Input () readonly         : String = "";
  @Output() getDate     = new  EventEmitter()

  minDate: Date;
  constructor() {
    this.minDate = new Date('01/01/1980');
    this.maxDateValue = new Date('01/12/2050')
   }

  ngOnInit() {
    
  }
  ngOnChanges() {

    if(this.isDynamicMinDate){
      this.minDate = this.minDateValue
      // console.log(  this.minDate,this.maxDateValue)
    }
    
    this.form_group.get(""+this.form_field+"").valueChanges.subscribe(query => {
      this.getDate.emit(query)
    });
  }
}
