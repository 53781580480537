import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../constants/baseurl';

@Injectable({
  providedIn: 'root'
})
export class BulletinBoardsService {

  constructor(private _http: HttpClient) { }

  createBulletin(formData, clientId) {
    return this._http.post(global.baseUrl + 'clients/' + clientId + '/bulletinboards', formData).pipe()
  }
  allApproved(clientId) {
    return this._http.get(global.baseUrl + 'clients/' + clientId + '/bulletinboards?status=APPROVED').pipe()
  }
  pendingApproval(clientId) {
    return this._http.get(global.baseUrl + 'clients/' + clientId + '/bulletinboards?status=CREATED').pipe()
  }

  myPosts(clientId) {
    return this._http.get(global.baseUrl + 'clients/' + clientId + '/bulletinboards?status=MYPOST').pipe()
  }
  deleteBulletin(bulletinId) {
    return this._http.delete(global.baseUrl + 'bulletinBoards/' + bulletinId).pipe()
  }
  postComments(formData, bulletinId) {
    return this._http.post(global.baseUrl + 'bulletinBoards/' + bulletinId + '/bulletinBoardComment', formData).pipe()
  }
  getComments(bulletinId) {
    return this._http.get(global.baseUrl + 'bulletinBoards/' + bulletinId + '/bulletinBoardComments').pipe()
  }
  getBulletinsById(bulletinId) {
    return this._http.get(global.baseUrl + 'bulletinBoards/' + bulletinId).pipe()
  }
  editBulletin(formData, bulletinId) {
    return this._http.post(global.baseUrl + 'bulletinBoards/' + bulletinId, formData).pipe()
  }
}