import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DateToEpochPipe } from '../../../common/pipes/date-to-epoch.pipe';
import { AllUsersService } from '../../all-users.service';
import { element } from 'protractor';

@Component({
  selector: 'app-view-admin-details',
  templateUrl: './view-admin-details.component.html',
  styleUrls: ['./view-admin-details.component.scss']
})
export class ViewAdminDetailsComponent implements OnInit {

  @ViewChild('primaryModal', { static: false }) public primaryModal: ModalDirective;
  userForm                     :  FormGroup;
  _convertEpoch                : DateToEpochPipe;
  documentName;
  showDocPath                  = false;
  constructor(private fbuilder : FormBuilder, private auth: AllUsersService) { 
    this.userForm = this.fbuilder.group({
      'name'                  : "",
      'email'                 : "",
      'designation'           : "",
      'mobilenumber'          : "",
      'community'             : "",
      'address'               : "",
      'city'                  : "",
      'postalcode'            : "",
      'state'                 : "",
      'country'               : "",
      'emirates_id'           : "",
      'expiry_of_emirates_id' : "",
      'doc_Emirates_id'       : "",
      
    })
    this.userForm.disable();
    
  }

  ngOnInit() {
    this._convertEpoch          = new DateToEpochPipe();

  }

  showPopup(type) {
    console.log(type.records.id, "record =>")
    this.getAdminDetails(type.records.id)
    this.primaryModal.show()
  }

  dismissModal() {
    this.primaryModal.hide()
  }

  getAdminDetails(_id) {
      
    this.auth.getAdminUserDetail(_id).subscribe( response => {
   
      let adminUser = response['data']
      let firstname = adminUser.firstName != undefined ? adminUser.firstName : '';
      let middlename = adminUser.middleName != undefined ? adminUser.middleName : '';
      let lastname = adminUser.lastName != undefined ? adminUser.lastName : '';
      let name = firstname + ' ' + middlename + ' ' + lastname ;
      let state = adminUser.addresses[0].state
      let docs = adminUser.documents != undefined ? adminUser.documents : '';
      if(docs != ''){
        console.log("docs",docs)
        docs.forEach((element)=>{
          console.log("element.documentType.id",element.documentType.id)
          if(element.documentType.id == 1){
            this.documentName = element.documentName;
            this.showDocPath  = false;
            console.log("this.documentName",this.documentName)
            console.log(" this.showDocPath", this.showDocPath)
          }
          
        })
      }
      else{
        this.documentName = '';
        this.showDocPath  = true;
      }
      let allCommunity = adminUser.communities
      let communitiesString = ""
      allCommunity.forEach(element => {
        communitiesString += element.name + "\n"
      })
 
      this.userForm.controls.name.setValue(name);
      this.userForm.controls.email.setValue(adminUser.emails[0].email);
      this.userForm.controls.designation.setValue(adminUser.userGroup.groupName);
      this.userForm.controls.mobilenumber.setValue(adminUser.phoneNumbers[0].phoneNumber);
      this.userForm.controls.community.setValue(communitiesString);
      this.userForm.controls.address.setValue(adminUser.addresses[0].address);
      this.userForm.controls.city.setValue(adminUser.addresses[0].city);
      this.userForm.controls.postalcode.setValue(adminUser.addresses[0].postalCode);
      this.userForm.controls.state.setValue(state.state);
      this.userForm.controls.country.setValue(state.country.countryName);
      this.userForm.controls.emirates_id.setValue(adminUser.emiratesId);
      this.userForm.controls.expiry_of_emirates_id.setValue(docs.expiryDate);
    })
  }

}
