import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-core-text-input',
  templateUrl: './core-text-input.component.html',
  styleUrls: ['./core-text-input.component.scss']
})
export class CoreTextInputComponent implements OnInit {
  
  @Input () form_group      : FormGroup;
  @Input () form_field      : FormControlName;
  @Input() label            : String;
  @Input() required         : boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
