import { Component, OnInit ,ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-table-component',
  templateUrl: './table-component.component.html',
  styleUrls: ['./table-component.component.scss']
})
export class TableComponentComponent implements OnInit {
  tableArray  : any = [];
  @ViewChild('primaryModal', {static: false}) public primaryModal: ModalDirective;
  selectedDate;
  status;
  selectedKey;
  selectedIndex
  constructor() { }

  ngOnInit() {
      this.tableArray.push({ id :'ELEVATOR/ARCANE',period : 'MONTHLY',month1 : '',month2 : '',month3 : '',month4 : '',month5 : '',month6 : '',month7 : '',month8 : '',month9 : '',month10 : '',month11 : '',month12 : ''},{id :'LIFT 3RD PARTY/BE SAFE',period : 'BI-ANNUALLY	',month1 : '',month2 : '',month3 : '',month4 : '',month5 : '',month6 : '',month7 : '',month8 : '',month9 : '',month10 : '',month11 : '',month12 : ''},{id :'FIRE PROTECTION/ 911/CG TECH	',period : 'QUARTERLY',month1 : '',month2 : '',month3 : '',month4 : '',month5 : '',month6 : '',month7 : '',month8 : '',month9 : '',month10 : '',month11 : '',month12 : ''},{id :'DCD 24X7 SYSTEM ANNUAL RENEWAL',period : 'ANNUALLY',month1 : '',month2 : '',month3 : '',month4 : '',month5 : '',month6 : '',month7 : '',month8 : '',month9 : '',month10 : '',month11 : '',month12 : ''},{id :'PEST CONTROL/ RENTOKIL/AL RASA',period : 'MONTHLY-CA BI ANNUAL-IU	',month1 : '',month2 : '',month3 : '',month4 : '',month5 : '',month6 : '',month7 : '',month8 : '',month9 : '',month10 : '',month11 : '',month12 : ''},{id :'WATER TANK CLEANING/ STALWART',period : 'BI-ANNUALLY',month1 : '',month2 : '',month3 : '',month4 : '',month5 : '',month6 : '',month7 : '',month8 : '',month9 : '',month10 : '',month11 : '',month12 : ''});

  }

  appendDatePicker(period,index,key){
    this.primaryModal.show();
    this.selectedKey    = key;
    this.selectedIndex  = index
    
    
  }
  updateStatus(){
    this.tableArray[this.selectedIndex][this.selectedKey] = this.selectedDate;
    console.log(this.tableArray,"tableArray")
    this.primaryModal.hide()
  }
}
