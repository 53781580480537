import {Injectable} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Injectable({
    providedIn : 'root'
})
export class AuthService {

    private authToken: string;
    private userEmail : string;
    private refreshToken : string

    constructor(private router : Router) {}
    
    isAuthenticated(): boolean {
        this.getAuthToken();
        return this.authToken != null;
    }
    
    getAuthToken(){
        
        if (!this.authToken) {
            this.authToken          = localStorage.getItem('authToken');
            this.userEmail          = localStorage.getItem('userEmail'); 
        }
        return this.authToken;
    }

    setAuthToken(token: string,email : string) {
        this.authToken          = token;
        this.userEmail          = email;
        localStorage.setItem('authToken', token);
        localStorage.setItem('userEmail', email);
        
    }

    logout(): any {
             
        this.authToken          = undefined;
        this.userEmail          = undefined;
        localStorage.clear()
        this.router.navigate(['/']);
        // this.router.navigate(['/'], { queryParams: { returnUrl: '/dashboard' } });
    }
}