import { Component, OnInit,Input } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-core-text-area',
  templateUrl: './core-text-area.component.html',
  styleUrls: ['./core-text-area.component.scss']
})
export class CoreTextAreaComponent implements OnInit {
  @Input () form_group            : FormGroup;
  @Input () form_field            : FormControlName;
  @Input () label                 : String ;
  @Input () placeHolder           : String;
  @Input () required              : boolean  = true;
  @Input () txtClass              : String;
  @Input () public disabledValue  : boolean;
  @Input() requiredError     : string;
  @Input() validationError  : string;
  

  constructor() { }

  ngOnInit() {
  }

}
