interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItem: NavData[] = [
  {
    name: 'Home', // admin,FM,OAM
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Admin Users',
    url: '/users',
    icon: 'icon-user',
  },
  {
    name: 'Unit Users', // admin ,FM (VIEW),ACCOUNTS(VIEW),SERVICE DESK(VIEW),OAM(VIEW)
    url: '/users/unitUsers',
    icon: 'icon-user',
  },
  {
    name: 'Community', // admin
    url: '/community',
    icon: 'icon-list',
   
  },
  {
    name: 'Developer', // admin
    url: '/developer',
    icon: 'icon-user',
   
  },
  {
    name: 'Notifications', // admin
    url: '/notification',
    icon: 'icon-list',
   
  },
  {
    name: 'Service Providers', // admin,FM,OAM
    url: '/service-provider/service_provider_listing',
    icon: 'icon-wrench',
  },
  {
    name : 'Risk- Dashboard',
    url : '/risk',
    icon: 'icon-star',
  },
  {
    name: 'Projects',
    url : '/projects',
    icon: 'icon-note'
  },

  {
    name: 'Service Request', // admin,FM ,ACCOUNTS,SERVICE DESK,OAM
    url: '/service-request',
    icon: 'icon-list',
    children : [
    {
      name: 'Raised by me',
      url : '/service-request/service-request-listing',
      icon: 'icon-note',
    },
    {
      name: 'Owned by my Group',
      url : '/service-request/owned-by-my-group',
      icon: 'icon-note',
    },
      {
        name: 'Create SR ',
        url : '/service-request/create-service-request',
        icon: 'icon-info'
       
      },
      {
        name: 'Search SR ',
        url : '/service-request/search-service-request',
        icon: 'icon-info'
      },
      {
        name: 'QA List ',
        url : '/service-request/create-qa',
        icon: 'icon-info'
      },
     
     
    ],
  },{
    name: 'Inspections',
    url: '/inspections',
    icon: 'icon-list',
   
  },
  {
    name : 'Tasks',
    url : '/tasks',
    icon: 'icon-star',
  },
  // {
  //   name : 'Tasks - PAM Users',
  //   url : '/tasks-pam-users',
  //   icon: 'icon-star',
  // },
  // {
  //   name : 'Forms', // admin,FM,ACCOUNTS,OAM
  //   url : '/forms',
  //   icon: 'icon-star',
  // },

  {
    name : 'Bulk Upload', //ACCOUNTS
    url : '/payment',
    icon: 'icon-list',
  },
  {
    name : 'Events',
    url : '/events',
    icon: 'icon-star',
  },
  {
    name : 'Bulletin Boards',
    url : '/bulletin-boards',
    icon: 'icon-list',
  },
  {
    name : 'Collections', //ACCOUNTS
    url : '/collections',
    icon: 'icon-star',
  },
  {
    name : 'Guest',
    url : '/guest-registration',
    icon: 'icon-star',
    children : [
      {
        name: 'Guest registration',
        url : '/guest-registration',
        icon: 'icon-user',
      },
      {
        name: 'Guest listing',
        url : '/guest-registration/guest-listing',
        icon: 'icon-user',
      }]
  },
  {
    name : 'Export Data',
    url : '/reports/export-data',
    icon: 'icon-star',
  },
  {
    name : 'Reports',
    url : '/reports',
    icon: 'icon-star',
    children : [
      {
        name: 'User Management',
        url : '/reports/user-management-list',
        icon: 'icon-user',
      },
      {
        name: 'Notifications',
        url : '/reports/notification-list',
        icon: 'icon-user',
      },
      {
        name: 'Guest Registrations',
        url : '/reports/guest-list',
        icon: 'icon-user',
      },{
        name: 'Bulletins',
        url : '/reports/bulletins-list',
        icon: 'icon-user',
      },{
        name: 'Service Request',
        url : '/reports/service-list',
        icon: 'icon-user',
      },{
        name: 'Task Registry',
        url : '/reports/task-list',
        icon: 'icon-user',
      },{
        name: 'Community',
        url : '/reports/community-list',
        icon: 'icon-user',
      },{
        name: 'Parking',
        url : '/reports/parking-list',
        icon: 'icon-user',
      },{
        name: 'Payment & Invoice',
        url : '/reports/payment-list',
        icon: 'icon-user',
      },{
        name: 'Collections',
        url : '/reports/collection-list',
        icon: 'icon-user',
      },{
        name: 'Agendas & Meetings',
        url : '/reports/agenda-list',
        icon: 'icon-user',
      }]
  }
  
];