import { Component, OnInit,Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-core-spinner',
  templateUrl: './core-spinner.component.html',
  styleUrls: ['./core-spinner.component.scss']
})
export class CoreSpinnerComponent implements OnInit {
 @Input() showTable = false;
  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }
  dataLoader(){
    /** spinner starts on init */
    if(!this.showTable)
       this.spinner.show();
    else
        this.spinner.hide();
 }
}
