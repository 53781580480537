import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FirstTimeLoginService} from '../services/first-time-login.service';
import { Router } from '@angular/router';
import {MustMatch} from '../password-match';
import * as global from '../../constants/baseurl';
import { Location } from '@angular/common';

@Component({
  selector: 'app-first-time-login',
  templateUrl: './first-time-login.component.html',
  styleUrls: ['./first-time-login.component.scss']
})
export class FirstTimeLoginComponent implements OnInit {
  firsttimeloginForm          : FormGroup;
  submitted                   : boolean;
  error                       : boolean;
  userData                    : any;
  @Input() userId             : any;
  title                       = "Update Password";
  @Output()                   updatePassword = new EventEmitter()
  timeInterval: boolean;
  constructor(private location: Location,private formBuilder: FormBuilder,private firstTimeLoginService: FirstTimeLoginService,private router:Router) {
   
  }

  ngOnInit() {
    this.firsttimeloginForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required]]
    },{
      validator: MustMatch('password', 'confirm_password')
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.firsttimeloginForm.controls; }

  onSubmit() {

    if(this.firsttimeloginForm.valid) { 

       this.firstTimeLoginService.firsttimeLogin(this.firsttimeloginForm.value.otp,this.firsttimeloginForm.value.password,this.userId).subscribe(response => {
        this.timeInterval = true;
        setTimeout(() => {
          this.timeInterval = false;
        }, 1000)
       },(error) => {
        global.showSwalMessage(error.error.message,'error','Error!')
      },() => {
        global.showSwalMessage('Password updated successfully !! Please login to continue','success','Success!').then((result) => {
          console.log(result)
          this.router.navigate(['/login']);
          window.location.reload();
          localStorage.clear()
          
        })
      }) 
    } else {
      this.markFormGroupTouched(this.firsttimeloginForm)
    }
  }
  
  private markFormGroupTouched(formGroup: FormGroup) { 

    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  
  

    
}
