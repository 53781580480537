import { Component, OnDestroy, Inject, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItem } from '../../_nav';
import { Router } from '@angular/router';
import { AuthService } from '../../views/common/shared-service/auth-service'
import { Renderer2 } from '@angular/core';
import { NotificationMenuDetailComponent } from './notification-menu-detail/notification-menu-detail.component';
import { DefaultLayoutService } from './default-layout.service';
import { AppService } from '../../app.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})

export class DefaultLayoutComponent implements OnDestroy {
  @ViewChild('toggleButton',{static: false}) toggleButton: ElementRef;
  @ViewChild('menu',{static: false}) menu: ElementRef;
  @ViewChild('modal',{ static: false }) modal: NotificationMenuDetailComponent;

  public navItems = [];
  public navItem = navItem;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  userData;
  menu_name: [];
  loginedClientId;
  menuDetails: any;
  loginUserGroup: any;
  loginUserName: string;
  userDetails;
  showNotificationList = false

  constructor(public appService: AppService,private renderer: Renderer2, private defaultLayoutService: DefaultLayoutService, private authService: AuthService, private router: Router, @Inject(DOCUMENT) _document?: any) {
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(this.toggleButton != undefined) {
        if(!this.toggleButton.nativeElement.contains(e.target)) {
          this.showNotificationList=false;
      }
      }
      
  });
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });

    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    this.userData = JSON.parse(localStorage.getItem('userdata'));
    this.userDetails = JSON.parse(localStorage.getItem('data'));
    if(this.userData != null){
      this.loginedClientId = this.userData['data'].userGroup.id;
      this.loginUserGroup = this.userData['data'].userGroup.groupName
    }

    if (this.loginedClientId == 8) {
      this.loginUserName = this.userData['data'].company
    } else if(this.userData != null) {
      this.loginUserName = this.userData['data'].firstName + ' ' + this.userData['data'].lastName
    }

    this.defaultLayoutService.getSidebarDetails(this.loginedClientId).subscribe(response => {
      this.menuDetails = response['data'];

      // Add guest and parking menus for SP staff - hard coded values
      if(this.loginedClientId == 9 && (this.userDetails.isSecurityStaff == true)) {
        this.menuDetails.push(
          {webScreen: 
            {"screen": 'Guest', 
            "icon": "icon-star", 
            "url": "/guest-registration", 
            "webScreenChildren": 
              [{name: "Guest registration", url: '/guest-registration'}, 
               {name: "Guest Listing", url: '/guest-registration/guest-listing'}]
        }}, 
        {webScreen: 
            {"screen": 'Parking Violation', 
            "icon": "icon-star", 
            "url": "/community/communityUsers/parking-violation",
            "webScreenChildren": []
        }}
        )
      }

      this.menuDetails.forEach((element, i) => {
        this.navItems.push({
          name: element.webScreen.screen,
          url: element.webScreen.url,
          icon: element.webScreen.icon,
          children: []
        })

        if (element.webScreen.webScreenChildren.length > 0) {
          element.webScreen.webScreenChildren.forEach((item) => {
            this.navItems[i].children.push({
              name: item.name,
              url: item.url,
            })
          })
        }
      })
    })
    this.getAllNotify()
  }

  ngOnInit() {
  
  }

  ngOnDestroy(): void {
    this.changes.disconnect(); 
  }

  isDivider(navItem) {
    return !!navItem.divider
  }

  isTitle(navItem) {
    return !!navItem.title
  }

  isHasChild(navItem) {
    return navItem.hasOwnProperty('children') && navItem.children.length > 0;
  }
  openNotification(event) {
    this.showNotificationList = !this.showNotificationList
  }
  dismissNotification(event) {
    this.showNotificationList = false
    this.modal.showPopup(event);
    this.defaultLayoutService.readNotification(event.id).subscribe(() => {
      this.getAllNotify()
    }, (error) => {
      console.log(error)
    })
  }

  removeNotification(event) {
    this.defaultLayoutService.deleteNotification(event.id).subscribe(notification => {
      this.getAllNotify()
    }, (error) => {
      console.log(error)
    })
  }

  logOut() {

    localStorage.removeItem('authToken');
    localStorage.removeItem('userEmail');
    localStorage.clear();
    this.authService.logout();
    this.appService.updateNotificationData([])
        this.appService.updateNotifyCount(0)

  }

  getAllNotify() {
    this.defaultLayoutService.getAppNotification().subscribe( notification => {
      if(notification != null) {
        this.appService.updateNotificationData(notification['data']['notifications'])
        this.appService.updateNotifyCount(notification['data']['count'])
      }
    }, (error)=> {
      console.log("error : ",error)
    }) 
  }
}
