import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { baseUrl } from '../constants/baseurl';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  // User Management

  getInternalUsers(id :any): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/internalUsers').pipe()
  }
  getTenants(id :any): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/tenants').pipe()
  }
  getRegisteredUsers(id :any): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/registeredUsers').pipe()
  }
  getPendingUsers(id :any): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/pendingUsers').pipe()
  }
  getNeverLoggedInUsers(id : any): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/usersNeverLoggedIn').pipe()
  }
  getServiceProviders(id : any): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/spService').pipe()
  }
  getRegisteredStaffs(id : any): Observable<any>{
    return this.http.get(baseUrl+'clients/'+id+'/serviceProviderStaffs').pipe()
  }

  // Events And Bulletins

  getEventsData(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/events/reports?startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getApprovedBulletins(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/approvedBulletins?startDate='+startDate+'&endDate='+endDate+'&status=APPROVED').pipe()
  }
  getPendingBulletins(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/approvedBulletins?startDate='+startDate+'&endDate='+endDate+'&status=CREATED').pipe()
  }

  // Guest Registrations
  getGuestsCheckedIn(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/guestList?from='+startDate+'&to='+endDate+'&guestType=Guest&flag=CheckIn').pipe()
  }
  getGuestsCheckedOut(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/guestList?from='+startDate+'&to='+endDate+'&guestType=Guest&flag=CheckOut').pipe()
  }
  getGuestsNotCheckedOut(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/guestList?from='+startDate+'&to='+endDate+'&guestType=Guest&flag=NotCheckOut').pipe()
  }
  getSPsCheckedIn(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/guestList?from='+startDate+'&to='+endDate+'&guestType=Service Provider&flag=CheckIn').pipe()
  }
  getSPsCheckedOut(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/guestList?from='+startDate+'&to='+endDate+'&guestType=Service Provider&flag=CheckOut').pipe()
  }
  getSPsNotCheckedOut(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/guestList?from='+startDate+'&to='+endDate+'&guestType=Service Provider&flag=NotCheckOut').pipe()
  }
  // Service Request
  getServiceRequestsReceived(client_id,id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'clients/'+client_id+'/communities/'+id+'/serviceRequests/report?endDate='+endDate+'&startDate='+startDate).pipe()
  }
  getServiceRequestsClosed(client_id,id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'clients/'+client_id+'/communities/'+id+'/serviceRequests/report?endDate='+endDate+'&startDate='+startDate+'&status=closed').pipe()
  }
  getServiceRequestStaffs(client_id,id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'clients/'+client_id+'/communities/'+id+'/serviceRequests/staff?status=closed&endDate='+endDate+'&startDate='+startDate).pipe()
  }
  getServiceRequestPendingStaffs(client_id,id): Observable<any>{
    return this.http.get(baseUrl+'clients/'+client_id+'/communities/'+id+'/serviceRequests/staff?status=in progress').pipe()
  }
  getPendingServiceRequests(client_id,id,staff_id): Observable<any>{
    return this.http.get(baseUrl+'clients/'+client_id+'/communities/'+id+'/staff/'+staff_id+'/serviceRequests?status=in progress').pipe()
  }
  getServiceRequestReports(client_id,id,staff_id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'clients/'+client_id+'/communities/'+id+'/staff/'+staff_id+'/serviceRequests?status=closed&internalStatus=Completed&endDate='+endDate+'&startDate='+startDate).pipe()
  }

  // Parking
  getParkingAllocated(id): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/parkingSlots').pipe()
  }  
  getParkingViolations(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/violatedparkingSlots?startDate='+startDate+'&endDate='+endDate).pipe()
  }  
  getTemporaryvsAllocated(id): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/temporaryParkingSlotVsAllocatedParking').pipe()
  } 
  getCarsParkedOvernight(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/parkedOvernight?startDate='+startDate+'&endDate='+endDate).pipe()
  }

  // Task 
  getTaskByCommunity(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/taskSchedules?isSuperAdmin=true&startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getTaskByPam(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/taskSchedules?isSuperAdmin=false&startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getClosedTask(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/taskSchedules?status=closed&startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getPendingTask(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/taskSchedules?status=pending&startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getAllCreatedTasksById(id): Observable<any>{
    return this.http.get(baseUrl+'taskSchedules/'+id).pipe();
  }
  getUserGroup(): Observable<any>{
    return this.http.get(baseUrl+'userGroups?groupType=Internal User').pipe();
  }
  getUserGroupOfSuperAdmin(): Observable<any>{
    return this.http.get(baseUrl+'userGroups?groupType=Super Admin').pipe();
  }

  // Payment
  getAmountInvoiced(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/invoices/reports?endDate='+endDate+'&startDate='+startDate).pipe()
  } 
  getAmountReceived(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/payments?endDate='+endDate+'&startDate='+startDate).pipe()
  } 
  getAmountOutstanding(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/outStandingInvoices?startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getAmountOutstandingPerQuarter(id,startDate,endDate,quarter,year): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/outStandingInvoices?startDate='+startDate+'&endDate='+endDate+'&quarter='+quarter+'&year='+year).pipe()
  }
  getSummaryofUnits(id,date): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/accountStatement?date='+date).pipe()
  }

  //Collections
  getForwardtoOam(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/forwardToOAManager?startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getCallbackOnDate(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/invoices?status=Call back&startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getCallsperday(id,startDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/noOfCallsPerDay?startDate='+startDate).pipe()
  }
  getCallNextday(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/invoices?status=Call Next Day&startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getRequestforInformation(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/invoices?status=Request for Information&startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getPromisetopay(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/invoices?status=Promise to Pay&startDate='+startDate+'&endDate='+endDate).pipe()
  }
  getForwardToLegal(id,startDate,endDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/invoices?status=Forwarded to Legal Action&startDate='+startDate+'&endDate='+endDate).pipe()
  }

  //Community
  getCommunity(): Observable<any>{
    return this.http.get(baseUrl+'communities').pipe()
  }
  getMaintanenceHistory(id): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/maintenanceHistoryOfAssets').pipe()
  }
  getAmcDue(id,cutoffDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/amcDueForRenewal?cutOffDate='+cutoffDate).pipe()
  }
  getRenewal(id,cutoffDate): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/certificatesDueForRenewal?cutOffDate='+cutoffDate).pipe()
  }
  getPreventivemaintananceDue(id,action): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/plannedPreventiveMaintenances?action='+action).pipe()
  }
  getPreventivemaintananceCompleted(id,action): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/plannedPreventiveMaintenances?action='+action).pipe()
  }
  getPreventivemaintanancePending(id,action): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/plannedPreventiveMaintenances?action='+action).pipe()
  }

  // notification
  exportNotification(id,start_date,end_date): Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/reports/exportNotification?startDate='+start_date+'&endDate='+end_date).pipe()
  }

  // export to excel
  getAllExcels(): Observable<any>{
    return this.http.get(baseUrl+'excels').pipe()
  }
} 
