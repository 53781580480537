import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from '../../views/constants/baseurl';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DefaultLayoutService {

  constructor(private http: HttpClient) {
   }

  getSidebarDetails(userGroupId): Observable<any> {
    return this.http.get(baseUrl + 'userGroup/' + userGroupId + '/userScreenMappings').pipe()
  }

  getAppNotification() {
    let clientId = localStorage.getItem('clientId');
    return this.http.get(baseUrl + "clients/" + clientId + "/notifies").pipe()

  }

  readNotification(notifyId) {
    let clientId = localStorage.getItem('clientId');
    return this.http.patch(baseUrl + "clients/" + clientId + "/notifies/" + notifyId,{}).pipe()
  }

  deleteNotification(notifyId) {
    let clientId = localStorage.getItem('clientId');
    return this.http.delete(baseUrl + "clients/" + clientId + "/notifies/" + notifyId,{}).pipe()
  }

}
