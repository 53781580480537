import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreTextBoxComponent } from './core-text-box/core-text-box.component';
import { CoreTableComponent } from './core-table/core-table.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { CoreDropdownComponent } from './core-dropdown/core-dropdown.component';
import { CommonModule } from '@angular/common';
import { CoreTextAreaComponent } from './core-text-area/core-text-area.component';
import { CoreInputFileComponent } from './core-input-file/core-input-file.component';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap';
import { CountdownModule } from 'ngx-countdown';
import { CoreDatepickerComponent } from './core-datepicker/core-datepicker.component';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from "ngx-spinner";
import { CoreButtonComponent } from './core-button/core-button.component';
import { CoreSidebarComponent } from './core-sidebar/core-sidebar.component';
import { CoreToggleSwitchComponent } from './core-toggle-switch/core-toggle-switch.component';
import { CoreTextInputComponent } from './core-text-input/core-text-input.component';
import { CoreNotesComponent } from './core-notes/core-notes.component';
import { CoreTimepickerComponent } from './core-timepicker/core-timepicker.component';
import { CoreRadioButtonComponent } from './core-radio-button/core-radio-button.component';
import { CoreBrowseComponent } from './core-browse/core-browse.component';
import { CoreBreadcrumbComponent } from './core-breadcrumb/core-breadcrumb.component';
import { CoreTimerComponent } from './core-timer/core-timer.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CoreDatalistComponent } from './core-datalist/core-datalist.component';
import { DateToEpochPipe } from './pipes/date-to-epoch.pipe';
import { FormatHtmlPipe } from './pipes/format-html.pipe';
import { ReasonForRejectionComponent } from '../all-users/unit-users/reason-for-rejection/reason-for-rejection.component';
import { AddTaskComponent } from '../tasks/add-task/add-task.component';
import { CoreSpinnerComponent } from './core-spinner/core-spinner.component';
import { GuestDetailsComponent } from '../guest-registration/guest-details/guest-details.component';
import { GuestListComponent } from '../reports/guest-list/guest-list.component'
import { ViewAdminDetailsComponent } from '../all-users/admin-users/view-admin-details/view-admin-details.component';
import { ManageEventsComponent } from '../events/manage-events/manage-events.component';
import { ManageBulletinBoardsComponent } from '../bulletin-boards/manage-bulletin-boards/manage-bulletin-boards.component';
import { PaginationModule } from 'ngx-bootstrap';

const COMPONENTS = [CoreTextBoxComponent, CoreDatalistComponent, CoreTableComponent, CardHeaderComponent, CoreDropdownComponent, CoreTextAreaComponent, CoreInputFileComponent, CoreDatepickerComponent, CoreButtonComponent, CoreToggleSwitchComponent, CoreNotesComponent, CoreRadioButtonComponent, CoreBrowseComponent, CoreTimepickerComponent, CoreTimerComponent, CoreBreadcrumbComponent, CoreTextInputComponent, DateToEpochPipe, FormatHtmlPipe, ReasonForRejectionComponent, ModalModule, GuestDetailsComponent, ViewAdminDetailsComponent]
const routes: Routes = [
    {
        path: 'guest-registration/guest-details',
        component: GuestDetailsComponent
    },
    {
        path: 'reports/guest-list',
        component: GuestListComponent
    },
    {
        path: 'events/manageEvents',
        component: ManageEventsComponent,
    },
    {
        path: 'bulletin-boards/manage-bulletin-boards',
        component: ManageBulletinBoardsComponent
    },

]

@NgModule({
    imports: [NgxSpinnerModule, FormsModule, ReactiveFormsModule, CommonModule, BsDatepickerModule.forRoot(),
        DatepickerModule.forRoot(), DataTablesModule, TimepickerModule.forRoot(), CountdownModule, FileUploadModule, ModalModule, PaginationModule.forRoot(), RouterModule.forChild(routes)],
    declarations: [CoreTextBoxComponent, CoreTableComponent, CardHeaderComponent, CoreDropdownComponent, CoreTextAreaComponent, CoreInputFileComponent, CoreDatepickerComponent, CoreButtonComponent, CoreSidebarComponent, CoreToggleSwitchComponent, CoreTextInputComponent, CoreNotesComponent, CoreRadioButtonComponent, CoreBrowseComponent, CoreTimepickerComponent, CoreTimerComponent, CoreBreadcrumbComponent, CoreDatalistComponent, DateToEpochPipe, FormatHtmlPipe, ReasonForRejectionComponent, CoreSpinnerComponent, GuestDetailsComponent, GuestListComponent, ViewAdminDetailsComponent, ManageEventsComponent, ManageBulletinBoardsComponent],
    exports: [...COMPONENTS]
})
export class SharedCommonModule { }
