import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject} from 'rxjs';
import { baseUrl } from '../constants/baseurl';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  guestInfo = new BehaviorSubject([]);
  guestInfoStream = this.guestInfo.asObservable();
  
  constructor(private http: HttpClient) { }

  updateguestInfo(data: any){
    this.guestInfo.next(data)
  }

  getCommunity(): Observable<any>{
    return this.http.get(baseUrl+'communities').pipe()
  }
  getUnitByCommunity(communityId): Observable<any>
  {
    return this.http.get(baseUrl+'communities/'+communityId+'/units').pipe()
  } 
  createGuest(clientId,data):Observable<any>{
    return this.http.post(baseUrl+'clients/'+clientId+'/guest',data).pipe()
  }
  getRegisteredGuests(communityId){
    return this.http.get(baseUrl+'communities/'+communityId+'/guests?guestStatus=Registered').pipe()
    // return this.http.get(baseUrl+'clients/'+clientId+'/guestsByStatus?guestStatus=Registered').pipe()
  }
  getGuestById(guestId){
    return this.http.get(baseUrl+'guests/'+guestId).pipe()
  }
  guestCheckIn(clientId,guestId,data):Observable<any>{
    return this.http.post(baseUrl+'clients/'+clientId+'/guest/'+guestId+'/update?status=CheckIn',data).pipe()
  }
  guestCheckOut(clientId,guestId,data):Observable<any>{
    return this.http.post(baseUrl+'clients/'+clientId+'/guest/'+guestId+'/update?status=CheckOut',data).pipe()
  }
  getCheckedInGuests(communityId){
    return this.http.get(baseUrl+'communities/'+communityId+'/guests?guestStatus=CheckIn').pipe()
    // return this.http.get(baseUrl+'clients/'+clientId+'/guestsByStatus?guestStatus=CheckIn').pipe()

  }
  getCheckedOutGuests(communityId){
    return this.http.get(baseUrl+'communities/'+communityId+'/guests?guestStatus=History').pipe()
    // return this.http.get(baseUrl+'clients/'+clientId+'/guestsByStatus?guestStatus=History').pipe()
  }
}
