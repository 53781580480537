import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToEpoch'
})
export class DateToEpochPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var date = new Date(value),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    value = [day, mnth, date.getFullYear()].join("/");
    value = value + ' 00:00';
    var parts = value.match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/);
    return Date.UTC(+parts[3], parts[2] - 1, +parts[1], +parts[4], +parts[5]);

  }

  convertDate(value: any) {
    var date = new Date(value),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    value = [date.getFullYear(), mnth, day].join("-");
    return value
  }

  convertDateToDDMMYY(value: any) {
    var date = new Date(value),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    value = [day, mnth, date.getFullYear()].join("-");
    return value
  }
  convertDateToDDMMYYwithslash(value: any) {
    var date = new Date(value),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    value = [day, mnth, date.getFullYear()].join("/");
    return value
  }

  convertDateandTimeFromEpoch(value: any) {
    var date = new Date(value),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    value = [day, mnth, date.getFullYear()].join("-");
    var time = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    // var time = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + ':00'
    return value + ' at ' + time
  }

  returnTimeOnlyFromDateTime(dateTime) {
    var date = new Date(dateTime),
    hrs = ("0" + (date.getHours())).slice(-2),
    mnts = ("0" + (date.getMinutes())).slice(-2),
    scnds= ("0" + (date.getSeconds())).slice(-2);
 
    return hrs +':'+ mnts +':' + scnds +':' + '00';

  
  }


  returnHourandMinutes(dateTime) {
    var date = new Date(dateTime * 1000);
    return date.getHours() + ':' + date.getMinutes();
  }

  convertDateandTimeinGMT(value) {
    var date = new Date(value);
    var time = date.getUTCHours() + ':' + date.getUTCMinutes() + ':' + date.getUTCSeconds();
    value = [date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate()].join("-");
    return value + ' ' + time
  }

  convertDateandTimeinGMTwithTimestamp(value) {
    var date = new Date(value),

      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hrs = ("0" + (date.getHours())).slice(-2),
      mnts = ("0" + (date.getMinutes())).slice(-2),
      scnds= ("0" + (date.getSeconds())).slice(-2);
    var time =hrs +':'+ mnts +':' + scnds;
    value = [date.getFullYear(), mnth, day].join("-");
    return value + 'T' + time
  }


  returnTimeOnlyFromDateTimewithoutSeconds(dateTime) {
    var date = new Date(dateTime);
    return '0' + date.getHours() + ':' + date.getMinutes();
  }

  convertDateToYYMMDD(value: any) {
    // console.log("&&&&&&&&&&",value)
    var date = new Date(value),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    value = [date.getFullYear(), mnth, day].join("-");
    // console.log(mnth,day)
    return value
  }

  removeMillisecondsFromTime(dateTime){
    var date = new Date(dateTime),
    hrs = ("0" + (date.getHours())).slice(-2),
    mnts = ("0" + (date.getMinutes())).slice(-2),
    scnds= ("0" + (date.getSeconds())).slice(-2);
 
    return hrs +':'+ mnts +':' + scnds;
  }
}
