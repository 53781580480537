import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as global from '../constants/baseurl'

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private _http : HttpClient) { }

  registerEvent(formData){
    return this._http.post(global.baseUrl+'events',formData).pipe()
   }
   listEvents(clientId)
   {
     return this._http.get(global.baseUrl+'clients/'+clientId+'/events').pipe()
   }
   deleteEvents(eventId)
   {
    return this._http.delete(global.baseUrl+'events/'+eventId).pipe()
   }
   getEventsbyId(eventId)
   {
    return this._http.get(global.baseUrl+'events/'+eventId).pipe()
   }
   deleteEventphotoById(eventPhotoId)
   {
    return this._http.delete(global.baseUrl+'eventPhotos/'+eventPhotoId).pipe()
   }
   editEvents(eventId,formData)
   {
    return this._http.post(global.baseUrl+'events/'+eventId,formData).pipe()
   }
}
