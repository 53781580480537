import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import * as global from '../../constants/baseurl';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  updatePasswordForm        : FormGroup;
  submitted                 : boolean = false;
  title                     = "Change Password";

  constructor( private formBuilder: FormBuilder, private route: Router, private auth: LoginService) {

    this.updatePasswordForm  = this.formBuilder.group({
      'currentpassword'      : ['', [Validators.required, Validators.minLength(6)]],
      'newpassword'          : ['', [Validators.required, Validators.minLength(6)]],
      'confirmpassword'      : ['',  Validators.required],
    },{
      validators             : MustMatch('newpassword','confirmpassword')
    })
  }

  ngOnInit() {

    console.log("update password ngOnit")
  }

 
  get f() { return this.updatePasswordForm.controls; }

  onSubmit(formValue) {

    this.submitted = true;
    if (this.updatePasswordForm.invalid) {
      console.log("invalid");
        return;
    }
    this.auth.updatePassword(formValue.currentpassword, formValue.newpassword).subscribe(data => {
      global.showSwalMessage('Password updated successfully !!!','success','Success!').then((result) => {
        if (result.value) {
          this.route.navigate(['/login']);
        }
    })
          
    },(error)=> {
          let err = error.json()
          global.showSwalMessage(error.error.message,'error','Error!')
          console.log("Error => ",error.json())
    })
      
 
  }

}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}