import { BrowserModule } from '@angular/platform-browser';
import { NgModule,Provider,forwardRef } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe,DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './views/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import {ApiInterceptor} from '../app/views/common/shared-service/auth-interceptor.service';

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login-users/login/login.component';
import { UpdatePasswordComponent } from './views/login-users/update-password/update-password.component';
import { ForgotPasswordComponent } from './views/login-users/forgot-password/forgot-password.component';
import { FirstTimeLoginComponent } from './views/login-users/first-time-login/first-time-login.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
const APP_CONTAINERS = [
  DefaultLayoutComponent,
  
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Import routing module
import { AppRoutingModule } from './app.routing';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { TableComponentComponent } from './views/table-component/table-component.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ServiceProviderComponent } from './views/service-provider/service-provider.component';
import { DataTablesModule } from 'angular-datatables';
import { LoginUsersComponent } from './views/login-users/login-users.component';
import { AuthRouteGuard } from '../app/views/common/shared-service/auth-gurad';
import { PrivacyPolicyComponent } from './views/login-users/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './views/login-users/terms-and-conditions/terms-and-conditions.component'
import { ContactUsComponent } from './views/login-users/contact-us/contact-us.component';
import { NotificationsMenuListComponent } from './containers/default-layout/notifications-menu-list/notifications-menu-list.component';
import { NotificationMenuDetailComponent } from './containers/default-layout/notification-menu-detail/notification-menu-detail.component';
import { AppService } from './app.service';


export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    ModalModule,
    FormsModule,
    DataTablesModule,
    HttpModule,
    HttpClientModule,
    CommonModule,
    NgxSpinnerModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    // AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase),
    
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    TableComponentComponent,
    LoginUsersComponent,
    LoginComponent,
    UpdatePasswordComponent,
    ForgotPasswordComponent,
    FirstTimeLoginComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ContactUsComponent,
    NotificationsMenuListComponent,
    NotificationMenuDetailComponent,
    
   
  ],
  providers: [
    ApiInterceptor, API_INTERCEPTOR_PROVIDER,AuthRouteGuard, DatePipe,DecimalPipe,AsyncPipe,MessagingService, AppService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
