import {NgModule} from '@angular/core';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';

import { Routes, RouterModule } from '@angular/router';
import {SharedCommonModule} from '../common/common.module';
// import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
const routes : Routes = [
];
@NgModule({
    imports: [FormsModule,ReactiveFormsModule,RouterModule.forChild(routes),SharedCommonModule],
    declarations: [],
})

export class LoginModule{}
