import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
 
@Component({
  selector: 'app-core-browse',
  templateUrl: './core-browse.component.html',
  styleUrls: ['./core-browse.component.scss']
})
export class CoreBrowseComponent implements OnInit {
  @Input () label              = "Browse";
  @Input () labelTxt           = "Attachment";
  @Input () required : boolean = true;
  @Input () mandatory: boolean = false;
  @Output() eventNew           = new EventEmitter();
  @Input () tblClass           = "tbl_file";
  constructor() { }

  ngOnInit() {
  }
  public uploader:FileUploader = new FileUploader({})
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;
  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e; 
  }
  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }
  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
     this.uploader.queue.map((fileItem) => {
       fileItem.file;
    });

    this.eventNew.emit(this.uploader.queue)
  }
  reorderFiles(item) {
    item.remove()
    this.eventNew.emit(this.uploader.queue)
  }
}
