import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import {Dropdown} from '../models/dropdown';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-core-dropdown',
  templateUrl: './core-dropdown.component.html',
  styleUrls: ['./core-dropdown.component.scss']
})
export class CoreDropdownComponent implements OnInit {
  @Input () form_group        : FormGroup;
  @Input () form_field        : FormControlName;
  @Input () required          = true;
  @Input () label             : String ;
  @Input () dropdwonOptions   : Dropdown[];
  @Input () requiredError     : string;
  @Input () validationError   : string;
  @Output() changeValue     = new  EventEmitter()
  constructor() { }

  ngOnInit() {
  }
  onDropdownChange(changeValue){
   this.changeValue.emit(changeValue)
  }
}
