import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommunityService } from '../../community/community.service';
import { Dropdown } from '../../common/models/dropdown';
import { Router, ActivatedRoute } from '@angular/router';
import * as global from '../../constants/baseurl';
import { EventsService } from '../events.service';
import { validateDropDownValue } from '../../common/validators/common-dropdown.validators';
import { element } from 'protractor';
import { empty } from 'rxjs';
import { DateToEpochPipe } from '../../common/pipes/date-to-epoch.pipe';

@Component({
  selector: 'app-manage-events',
  templateUrl: './manage-events.component.html',
  styleUrls: ['./manage-events.component.scss']
})
export class ManageEventsComponent implements OnInit {
  eventsForm: FormGroup;
  title: string = '';
  community: Dropdown[] = [];
  communitLoads: boolean = false;
  isShowForm: boolean = false;
  clone = {};
  upload_photos: any;
  documentName: any;
  documentNames: any;
  descriptions_uploads: any;
  private items: Array<any> = [];
  public sections: any[] = [];
  _convertDate: DateToEpochPipe;
  editClass: any;
  userId: any;
  photosId_file: File;
  photo_file: File;
  communityList: any;
  documents_files: any;
  photos_file: any;
  header: any;
  event_photo_id: any[] = [];
  documentId: any;
  isValidDate: boolean;
  isMeridian: boolean = false;
  isValidTime: boolean;
  isValidEndTime: boolean;
  disable_button : boolean = false;

  constructor(private fbuilder: FormBuilder, private route: ActivatedRoute, private CommunityService: CommunityService, private router: Router, private EventsService: EventsService) {
    this._convertDate = new DateToEpochPipe();
    let value = this.route.params.subscribe(params => {
      this.userId = params.id;
      this.header = params.action;
      this.title = this.header + " events";
    });
    this.eventsForm = fbuilder.group({
      title: ['', [Validators.required]],
      upload_photo: [''],
      photo_upload: [''],
      upload_photos: [''],
      descriptions: [''],
      photo_uploads: [''],
      documentName: [""],
      documentNames: [""],
      event_date: ['', [Validators.required]],
      event_time: ['', { updateOn: 'change' }, Validators.required],
      event_end_date: ['', [Validators.required]],
      event_end_time: ['', { updateOn: 'change' }, Validators.required],
      community: ["-1", Validators.compose([Validators.required, validateDropDownValue])],
      description: ["", Validators.required],
      event_location: ["", Validators.required],
    }, { updateOn: 'blur' })
  }

  ngOnInit() {

    this.editClass = this.header;
    if (this.editClass == "Edit") {
      this.editClass = true;
    } else {
      this.editClass = false;
    }

    //community list
    this.community.push({ label: 'Select Community', value: '-1' });
    this.CommunityService.getCommunity().subscribe(response => {
      this.communityList = response['data'];
      this.communityList.forEach(element => {
        this.community.push({ label: element.name, value: element.id })
      })
    })


    if (this.userId != undefined && this.userId) {
      this.EventsService.getEventsbyId(this.userId).subscribe(response => {
        let eventDetails = response['data'];
        let description_details = [];
        let upload_details = [];
        if (eventDetails.eventPhoto != undefined) {
          eventDetails.eventPhoto.forEach(element => {
            this.sections.push({ description: element.description, id: element.id, index: this.sections.length, file: '', url: element.document != undefined ? element.document.documentName : '' });

          })
        }

        var time = (new Date(eventDetails.startDate));
        var etime = (new Date(eventDetails.endDate));
        var new_start_date = this._convertDate.convertDateToYYMMDD(time);
        var new_end_date = this._convertDate.convertDateToYYMMDD(etime);

        this.eventsForm.patchValue({
          title: eventDetails.title != undefined ? eventDetails.title : '',
          event_date: eventDetails.startDate != undefined ? time : '',
          event_end_date: eventDetails.endDate != undefined ? etime : '',
          event_time: eventDetails.startDate != undefined ? time : '',
          event_end_time: eventDetails.endDate != undefined ? etime : '',
          description: eventDetails.description != undefined ? eventDetails.description : '',
          event_location: eventDetails.location != undefined ? eventDetails.location : '',
          community: eventDetails.community != undefined ? eventDetails.community.id : '',
          photo_upload: eventDetails.document != undefined ? eventDetails.document.documentName : '',
          photo_upload_id: eventDetails.document != undefined ? eventDetails.document.id : '',

          photo_uploads: upload_details,
          descriptions: description_details,
        })
        this.documentName = eventDetails.document.documentName;
        this.documentNames = this.sections.length;

        this.documentId = eventDetails.document.id;

      })
    } else {
      this.setValidationForRegister();
    }
  }

  private setValidationForRegister() {
    const upload = this.eventsForm.get('photo_upload');
    upload.setValidators([Validators.required]);
    upload.updateValueAndValidity();

  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
  }
  val: any = { isError: false, errorMessage: '' };
  val_start_time: any = { isError: false, errorMessage: '' };
  val_end_time: any = { isError: false, errorMessage: '' };
  validateDates(sDate: string, eDate: string) {

    sDate = this._convertDate.convertDateToYYMMDD(sDate);
    eDate = this._convertDate.convertDateToYYMMDD(eDate);

    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) <= (sDate)) {
      this.val = { isError: true, errorMessage: 'End date should be greater than start date.' };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  validateStartTime(time: string) {
    this.isValidTime = true;
    if (time == '') {
      this.val_start_time = { isError: true, errorMessage: 'Please select start time' };
      this.isValidTime = false;
    }
    return this.isValidTime;
  }
  validateEndTime(time: string) {
    this.isValidEndTime = true;
    if (time == '') {
      this.val_end_time = { isError: true, errorMessage: 'Please select end time' };
      this.isValidEndTime = false;
    }
    return this.isValidEndTime;
  }

  OnChangeStartTime(value) {
    this.val_start_time = { isError: false, errorMessage: '' };
  }
  OnChangeEndTime(value) {
    this.val_end_time = { isError: false, errorMessage: '' };
  }

  addEvent(formValue) {
    this.isValidDate = this.validateDates(formValue.event_date, formValue.event_end_date);
    this.isValidTime = this.validateStartTime(formValue.event_time);
    this.isValidEndTime = this.validateEndTime(formValue.event_end_time);
    let startdate;
    let enddate;
    let check_date_format_startdate;
    let check_date_format_enddate;
    check_date_format_startdate = /\/.*\//.test(formValue.event_date);
    check_date_format_enddate = /\/.*\//.test(formValue.event_end_date);
    if (this.eventsForm.valid && this.isValidDate && this.isValidTime && this.isValidEndTime) {
      this.disable_button = true;
      if (this.userId != undefined && this.userId) {
        let formData: FormData = new FormData();

        if(check_date_format_startdate){
          var parts =formValue.event_date.split('/');
          startdate = new Date(parts[2], parts[1] - 1, parts[0]) 
          startdate = this._convertDate.convertDate(startdate)    + ' ' + this._convertDate.returnTimeOnlyFromDateTime(formValue.event_time); 
     
        }
        else{
          startdate = this._convertDate.convertDateToYYMMDD(formValue.event_date) + ' ' + this._convertDate.returnTimeOnlyFromDateTime(formValue.event_time);
        }

        if(check_date_format_enddate){
          var parts =formValue.event_end_date.split('/');
          enddate = new Date(parts[2], parts[1] - 1, parts[0]); 
          enddate = this._convertDate.convertDate(enddate)    + ' ' + this._convertDate.returnTimeOnlyFromDateTime(formValue.event_end_time); 
       
        }
        else{
          enddate = this._convertDate.convertDateToYYMMDD(formValue.event_end_date) + ' ' + this._convertDate.returnTimeOnlyFromDateTime(formValue.event_end_time);
        }

        var newtime = this._convertDate.convertDateandTimeinGMT(new Date(startdate).toUTCString());
        var event_end = this._convertDate.convertDateandTimeinGMT(new Date(enddate).toUTCString());

        formData.append("title", formValue.title);
        formData.append("community.id", formValue.community);
        formData.append("description", formValue.description);
        formData.append("startDate", newtime);
        formData.append("endDate", event_end);
        formData.append("location", formValue.event_location);

        if (this.photosId_file != undefined) {
          formData.append('document.file', formValue.photo_upload);
          formData.append('document.documentType.id', "26");
          formData.append('document.id', this.documentId);
        }
        var j = 0;
        for (var i = 0; i < this.sections.length; i++) {
          if (this.sections[i].file != undefined || this.sections[i].description != undefined) {
            let dkey = 'eventPhoto[' + j + '].description';
            formData.append(dkey, this.sections[i].description);
            if (this.sections[i].id != undefined) {
              let id_key = 'eventPhoto[' + j + '].id';
              formData.append(id_key, this.sections[i].id);
            }
            if (this.sections[i].file != '') {
              let fkey = 'eventPhoto[' + j + '].document.file';
              formData.append(fkey, this.sections[i].file);
              let ikey = 'eventPhoto[' + j + '].document.documentType.id';
              formData.append(ikey, "26");
              if (this.sections[i].id != undefined) {
                let id_key = 'eventPhoto[' + j + '].id';
                formData.append(id_key, this.sections[i].id);
              }
            }

            j++;
          }
        }
        this.EventsService.editEvents(this.userId, formData).subscribe(response => {

        }, (error) => {
          this.disable_button = false;
          global.showSwalMessage(error.error.message, 'error', 'Error!')
        }, () => {
          global.showSwalMessage('Events updated successfully', 'success', 'Success!').then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/events/events')
            }
          })
        })
      }
      else {
        let formData: FormData = new FormData();
        if(check_date_format_startdate){
          var parts =formValue.event_date.split('/');
          startdate = new Date(parts[2], parts[1] - 1, parts[0]); 
        }
        else{
          startdate = this._convertDate.convertDateToYYMMDD(formValue.event_date) + ' ' + this._convertDate.returnTimeOnlyFromDateTime(formValue.event_time);
        }
        if(check_date_format_enddate){
          var parts =formValue.event_end_date.split('/');
          enddate = new Date(parts[2], parts[1] - 1, parts[0]); 
        }
        else{
          enddate = this._convertDate.convertDateToYYMMDD(formValue.event_end_date) + ' ' + this._convertDate.returnTimeOnlyFromDateTime(formValue.event_end_time);
        }

        var newtime = this._convertDate.convertDateandTimeinGMT(new Date(startdate).toUTCString());
        var event_end = this._convertDate.convertDateandTimeinGMT(new Date(enddate).toUTCString());

        formData.append("title", formValue.title);
        formData.append("community.id", formValue.community);
        formData.append("description", formValue.description);
        formData.append("startDate", newtime);
        formData.append("endDate", event_end);
        formData.append("location", formValue.event_location);


        var j = 0;
        if (this.sections.length > 0) {
          for (var i = 0; i < this.sections.length; i++) {
            if (this.sections[i].file != "" || this.sections[i].description != "") {
              let dkey = 'eventPhoto[' + j + '].description';
              formData.append(dkey, this.sections[i].description);
              let fkey = 'eventPhoto[' + j + '].document.file';
              formData.append(fkey, this.sections[i].file);
              let ikey = 'eventPhoto[' + j + '].document.documentType.id';
              formData.append(ikey, "26");
              j++;
            }

          }
        }


        formData.append('document.file', formValue.photo_upload);
        formData.append('document.documentType.id', "26");

        this.EventsService.registerEvent(formData).subscribe(response => {

        }, (error) => {
          this.disable_button = false;
          global.showSwalMessage(error.error.message, 'error', 'Error!')
        }, () => {
          global.showSwalMessage('Events added successfully', 'success', 'Success!').then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/events/events')
            }
          })
        })
      }
    } else {
      this.markFormGroupTouched(this.eventsForm);

    }
  }
  readUrl_data(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        var url = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
      var fileElement = event.target;
      this.clone[fileElement.id] = fileElement.cloneNode(true)
    }
  }
  fileChange(e: Event) {
    var target: HTMLInputElement = e.target as HTMLInputElement;
    for (var i = 0; i < target.files.length; i++) {
      this.photosId_file = target.files[i];
    }

    if (target.value == '') {
      var file = document.getElementById("file_upload");
      file.parentNode.removeChild(file);
      document.getElementById("filediv").appendChild(this.clone[target.id]);
    }
    this.eventsForm.patchValue({
      photo_upload: this.photosId_file
    })
  }

  fileChanges(e: Event, index) {
    var target: HTMLInputElement = e.target as HTMLInputElement;
    for (var i = 0; i < target.files.length; i++) {
      this.photos_file = target.files[i];
    }
    this.sections[index].file = this.photos_file;

  }
  descriptionChanges(e: Event, index) {
    var target: HTMLInputElement = e.target as HTMLInputElement;
    let descriptions = target.value;
    this.sections[index].description = descriptions;
  }

  addRow(i) {
    this.sections.push({ index: this.sections.length, file: '', url: '', description: '' });
  }

  deleteItems(i: number, subindex) {
    this.sections[i].subItems.splice(subindex, 1)
  }
  deleteMainDiv(index, i) {
    if (index != undefined) {
      this.EventsService.deleteEventphotoById(index).subscribe(response => {
      }, () => {

      }, () => {
        this.sections.splice(i, 1)
      })
    } else {
      this.sections.splice(i, 1)
    }
  }
  cancelClik() {
    this.router.navigate(['/events/events']);
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  public findInvalidControls() {

    const invalid = [];
    const controls = this.eventsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
}
