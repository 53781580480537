import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { baseUrl } from '../constants/baseurl';

@Injectable({
  providedIn: 'root'
})
export class AllUsersService {

  _id = localStorage.getItem('clientId');

  constructor(private http: HttpClient) { 
    // console.log("client id -- ",this._id)
  }

  getAdminUsers(): Observable<any> {
    
    return this.http.get (baseUrl+'admins').pipe()
  } 

  getAdminUserDetail(_id) {

      return this.http.get(baseUrl + 'clients/' + _id).pipe();
  }

  // approve/reject inactive unit users
  approveUnitUser(userId, unitId, data): Observable<any> {
   
    return this.http.patch(baseUrl+'clients/' + userId + "/units/" + unitId ,data).pipe()
  }
  
  // to get all unit users under logged in adminuser
  getAllUsers(id) {
    
    return this.http.get(baseUrl+'clients/' + id +'/unitusers').pipe()
  }

  // to get user details to show detail on view & approve screen
  getUser(user_id_,unit_id) {
    return this.http.get(baseUrl+'clients/' + user_id_ + '/units/' + unit_id + '/getAllDetailsByUnit').pipe()
  }

  getUnitUserList(user_id_,unit_id) {

    return this.http.get(baseUrl+'clients/' + user_id_+'/units/' + unit_id).pipe()
  }
  patchDocuments(clientId,data){
    return this.http.post(baseUrl+'clients/' + clientId + "/documents" ,data).pipe()
  }

  updateUnitUserDetails(id,body) {
    return this.http.patch(baseUrl+'clients/' + id + "?action=patch" ,body).pipe()
  }
}
